import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import React from "react";

export const TimeAgo = ({date, bold = false, capitalize = false} : {date: any, bold?: boolean, capitalize?: boolean}) => {


    const thresholds = [
        { l: 'd', r: 1 },
        { l: 'ay', r: 2, d: 'day' },
        { l: 'dd', r: 29, d: 'day' },
        { l: 'M', r: 1 },
        { l: 'MM', r: 11, d: 'month' },
        { l: 'y' },
        { l: 'yy', d: 'year' },
        { l: 'yy', d: 'year' },
    ];


    dayjs.locale('es');
    dayjs.extend(relativeTime, {thresholds});
    dayjs.extend(updateLocale);

    dayjs.updateLocale('es', {
        relativeTime: {
            past: "[%s]",
            d: "[hoy]",
            ay: '[ayer]',
            dd: "hace [%d dias]",
            M: "hace [1 mes]",
            MM: "hace [%d meses]",
            y: "hace [un año]",
            yy: "hace [%d años]"
        }
    });

    let text = dayjs(date).fromNow(true);
    text = capitalize? text.charAt(0).toUpperCase() + text.slice(1) : text;
    if (bold) {
        const parts = text.split(/\[(.+)\]/gi);
        return (<span>{parts[0]}<b>{parts[1]}</b>{parts[2]}</span>);
    } else {
        return (<span>{text}</span>);

    }
};
