import "./styles.less";

import { useSEO } from "../hook";
import { useState } from "react";
import { SocialNetworks } from "shared-components/ViewFragments/Footer/SocialNetworks/web";
import { Institucional } from "shared-components/ViewFragments/Footer/MoreAboutUs/Institucional/web"


export const LinkBox = () => {
	const { seoMetaTags } = useSEO();

	if (!seoMetaTags?.links) return null;

	return (
		<div className="link-boxes">
			{Object.entries(seoMetaTags.links).map(([categoryName, links], i) => (
				<Box categoryName={categoryName} links={links} key={`linkBox-${i}`} />
			))}
			<SocialNetworks />
			<Institucional />
		</div>
	);
};

const Box = ({ categoryName, links }: { categoryName: string; links: any[] }) => {
	const [open, setOpen] = useState<boolean>(false);

	return (
		<div className="container-link-box">
			<div className={`link-box ${!open ? "close" : ""}`}>
				<h5>{categoryName}</h5>
				{links.map((l, i) => (
					<a key={`lb-link-${i}`} href={l.url}>
						{l.text}
					</a>
				))}
			</div>
			<button
				className="seeMoreBtn"
				onClick={() => {
					setOpen(!open);
				}}>
				{open ? "menos" : "Más"}
			</button>
		</div>
	);
};

