import { useState } from "react";
import { gql } from "@apollo/client";
import { useLazyQuery } from "@apollo/client";
import { FragmentDefiner } from "../../../../GlobalHooks/useReadFragment";

const IMAGES_QUERY = gql`
	query images($propertyId: ID!) {
		property(id: $propertyId) {
			images {
				id
				image
			}
			project {
				images {
					id
					image
				}
			}
		}
	}
`;

export const FRAGMENT_CARD_IMAGE = new FragmentDefiner(
	"Property",
	`
    img
    image_count
    youtube
`
);

export function useCardImageGallery({ id: propertyId, mode = "auto", image, youtube }) {
	const [gallery, setGallery] = useState(generateImgsArray(image));

	const [getImages, { loading, error, called }] = useLazyQuery(IMAGES_QUERY, {
		variables: { propertyId },
		onCompleted: data => {		
			if ((data?.property?.project[0] && mode == "auto") || mode == "project") {
				if (data.property.project[0].images.length > 0) {
					setGallery(generateImgsArray(image, data.property.project[0].images));
				}
			} else {
				if (data?.property?.images.length > 0) {
					setGallery(generateImgsArray(image, data.property.images));
				}
			}
		},
	});

	return {
		imagen: image,
		video: youtube,
		gallery,
		getImages,
		called,
		loading,
		error,
	};
}

/**
 * Auxiliary functions
 *
 */

const generateImgsArray = (mainImg = "", imgs = []) => {
	if (!!mainImg) mainImg = mainImg.replace("#HASTH", "").trim();
	let addMainImg = !imgs[0]?.image?.includes(mainImg);
	return addMainImg ? [{ image: mainImg }, ...imgs] : [...imgs];
};
