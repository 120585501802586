import { gql } from "@apollo/client";

export const MUTATION_SEND_REGISTER_INMOB = gql`
	mutation inmoRegister(
		$plan_id: Int!
		$email: String!
		$password: String!
		$password_confirmation: String!
		$type: RegisterTypes!
		$email_finance: String!
		$contact_person: String!
		$phone: String!
		$rut: String
		$ci: String
		$tax_name: String!
		$address: String!
		$business_hours: String
		$name: String!
		$logo: String!
		$description: String
		$subsidiaries: [RegisterSubsidiary!]!
	) {
		inmoRegister(
			input: {
				plan_id: $plan_id
				email: $email
				password: $password
				password_confirmation: $password_confirmation
				type: $type
				email_finance: $email_finance
				contact_person: $contact_person
				phone: $phone
				rut: $rut
				ci: $ci
				tax_name: $tax_name
				address: $address
				business_hours: $business_hours
				name: $name
				logo: $logo
				description: $description
				subsidiaries: $subsidiaries
			}
		) {
			access_token
			user_md5
		}
	}
`;
