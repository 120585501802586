import { moneyFragment, owner, price } from "./partials";

import { gql } from "@apollo/client";

export const QUERY_MY_LEADS = gql`
	query getMyLeads($first: Int!, $page: Int) {
		myLeads(first: $first, page: $page) {
			data {
				id
				message
				created_at
				sender_id
				seller_id
				owner_id
				unread
				to {
					name
					logo
					email	
					phone
				}
				from {
					name
					email
					phone
				}
				lastReply {
					message
					created_at
					sender
					seen
				}
				listing {
					__typename
					... on Property {
						id
						title
						img
						deleted
						active
					}
					... on Project {
						id
						title
						link
						isEspecial
						commercial_units {
							img
						}
						active
					}
				}
			}
			paginatorInfo {
				lastPage
				total
				count
				currentPage
			}
		}
	}
`;
