import React, { useContext } from "react";
import { ConfigStateContext } from "../../../Contexts/Configurations/context";

export type SocialMediaLink = {
	name: string;
	slug: string;
	url: string;
	icon: string;
};

export const useSocialNetworks = (): SocialMediaLink[] => {
	const { socialMediaLinks } = useContext(ConfigStateContext);

	return socialMediaLinks;
};
