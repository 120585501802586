import { useImage } from "./Image.hook"

type ImgOptimizedProps = {
    src: string
    alt?: string
    width?: number
    height?: number
    className?: string
    title?: string
    imageId?: string
    propertyId?: number
}

export const ImagenOptimizada = ({ src, alt = "", width, height, className = "", title, imageId, propertyId }: ImgOptimizedProps) => {
    const { getImagenOptimizada, notifyError } = useImage()
    const defaultImg = 'https://cdn1.infocasas.com.uy/web/66575677eea43_screenshot-2024-05-29-at-11.20.20.png'

    const srcUrl = getImagenOptimizada({
        src: src ?? '',
        width: width ?? 1,
        height: height ?? 1
    })

    return <img src={srcUrl && srcUrl.length > 0 ? srcUrl : defaultImg}
        alt={alt}
        width={width ?? 'auto'}
        height={height ?? 'auto'}
        className={className}
        title={title}
        onError={() => notifyError({
            image_url: src,
            image_id: imageId,
            property_id: propertyId
        })}
    />
}