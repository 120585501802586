import { RESULTS_PER_PAGE } from "shared-components/ViewFragments/SearchPage/Listings";
import fetcher from "src/utils/fetcher";
import getConfig from "next/config";

const { searchBase } = getConfig().publicRuntimeConfig;

type VariablesType = {
	rows: number;
	params: {
		id?: string;
		operation_type_id?: number;
		property_type_id?: number[];
		owner_id?: string;
	};
	page: number;
	source: number;
};

export const fetchSearchResults = async (variables: VariablesType) => {
	const body = {
		variables: variables,
		// TODO: Validate this query
		query: "",
	};
	return fetcher(`${searchBase}/api/v1/properties/search`, {
		method: "POST",
		body: JSON.stringify(body),
	})
		.then((res: any) => {
			const total = res?.hits?.total?.value || 0;
			const lastPage = Math.ceil(total / RESULTS_PER_PAGE);
			const hasMorePages = variables.page < lastPage;
			const firstItem = (variables.page - 1) * RESULTS_PER_PAGE + 1;
			const lastItem =
				variables.page * RESULTS_PER_PAGE > total
					? total
					: variables.page * RESULTS_PER_PAGE;

			return {
				property: res?.hits?.hits?.length > 0 ? res?.hits?.hits[0]._source?.listing : null,
				searchFast: {
					data: res?.hits?.hits.map((hit: any) => hit._source?.listing) || [],
					paginatorInfo: {
						currentPage: variables.page,
						firstItem,
						hasMorePages,
						lastItem,
						lastPage,
						perPage: RESULTS_PER_PAGE,
						total,
					},
				},
			};
		})
		.catch(e => {
			console.log(e);
			return { data: [], property: null };
		});
};

export const getLocations = async (locationText: string) => {
	const body = {
		operationName: "Location",
		variables: {
			strSearch: locationText,
		},
		query: "",
	};
	//TODO: set base url from env
	return fetcher(`${searchBase}/api/v1/locations/infofinca-autocomplete`, {
		method: "POST",
		body: JSON.stringify(body),
	})
		.then((res: any) => {
			return res?.data;
		})
		.catch(e => {
			console.log(e);
			return {
				searchLocation: [],
			};
		});
};

export const getSimilars = async (id: string, isProject: boolean, results: number) => {
	const body = {
		operationName: "similar",
		variables: {
			id,
			results_per_page: results,
			page: 1,
			isProject,
		},
		query: "",
	};
	//TODO: set base url from env
	return fetcher(`${searchBase}/api/v1/properties/similar`, {
		method: "POST",
		body: JSON.stringify(body),
	})
		.then((res: any) => {
			return res?.data?.similarById?.data;
		})
		.catch(e => {
			console.log(e);
			return {
				data: [],
			};
		});
};

