import { useFilters } from "../../../../../Components/Filters/Filters.hook";
import { useSearchResultsFilters } from "../../../Sider/Filters/SearchResultsFilters.hook";

const RESULTS_PER_PAGE = 21;

export const useSearchResultsPagination = (paginatorInfo, map = false, initialUrlUpdate = true, explicitFilters) => {
	const { filters } = explicitFilters ? { filters: explicitFilters } : useFilters();

	const { changeFiltersAndUpdateURL } = useSearchResultsFilters(true, initialUrlUpdate);

	const changePage = newPage => {
		if (newPage !== filters?.page) {
			changeFiltersAndUpdateURL({
				page: { value: newPage, text: "page" + newPage },
			});
		}
	};

	return {
		data: {
			count: RESULTS_PER_PAGE,
			currentPage: filters?.page || 1,
			lastPage: paginatorInfo?.lastPage,
			total: paginatorInfo?.total,
			perPage: RESULTS_PER_PAGE,
		},
		changePage,
	};
};
