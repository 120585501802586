export const SearchIcon = ({width = 22}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={width} viewBox="0 0 22 22" fill="none">
        <g clipPath="url(#clip0_704_4153)">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.14266 0.423828C6.85049 0.423828 4.65219 1.33436 3.03138 2.95513C1.41056 4.57589 0.5 6.77412 0.5 9.06623C0.5 11.3583 1.41056 13.5566 3.03138 15.1773C4.65219 16.7981 6.85049 17.7086 9.14266 17.7086C11.1174 17.7086 13.0224 17.0329 14.5485 15.8094L19.8853 21.1468C20.2546 21.5161 20.8535 21.5162 21.2229 21.1468C21.5923 20.7775 21.5924 20.1786 21.223 19.8092L15.8861 14.4718C17.1096 12.9457 17.7853 11.0408 17.7853 9.06623C17.7853 6.77412 16.8748 4.57589 15.254 2.95513C13.6331 1.33436 11.4348 0.423828 9.14266 0.423828ZM13.941 13.8149C15.1916 12.5513 15.8936 10.8449 15.8936 9.06623C15.8936 7.27582 15.1824 5.55874 13.9163 4.29273C12.6503 3.02672 10.9331 2.31549 9.14266 2.31549C7.3522 2.31549 5.63507 3.02672 4.36902 4.29273C3.10298 5.55874 2.39172 7.27582 2.39172 9.06623C2.39172 10.8566 3.10298 12.5737 4.36902 13.8397C5.63507 15.1057 7.3522 15.817 9.14266 15.817C10.9215 15.817 12.628 15.1149 13.8916 13.8643C13.8995 13.8558 13.9076 13.8474 13.9159 13.8391C13.9242 13.8308 13.9326 13.8227 13.941 13.8149Z" fill="currentColor" />
        </g>
        <defs>
            <clipPath id="clip0_704_4153">
                <rect width="21" height="21" fill="white" transform="translate(0.5 0.5)" />
            </clipPath>
        </defs>
    </svg>
)
