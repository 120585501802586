import { gql, useQuery } from "@apollo/client";

const COUNTRIES_QUERY = gql`
	{
		countries {
			id
			name
			order
			data {
				main_domain
				country_flag
			}
		}
	}
`;

const parseData = countries => {
	return countries.map(country => ({
		id: country.id,
		name: country.name,
		order: country.order,
		url: `//${country.data.main_domain}`,
		...country.data,
	}));
};

export const useCountries = () => {
	const { loading, error, data } = useQuery(COUNTRIES_QUERY);

	return {
		loading,
		countries: parseData(data?.countries ?? []),
		error,
	};
};
