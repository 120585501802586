import { gql } from "@apollo/client";

export const QUERY_BANNERS_HEADER = gql`
	query getBannersHeader($whereToShow: String) {
		bannerShopping {
			location
			date
			hours
			link
			country_id
			title
			background_color
			border_color
		}
		bannerHeader(whereToShow: $whereToShow) {
			id
			country_id
			title
			link

			html

			image
			text
			text_color
			background_color
			button_text
			button_text_color
			button_background_color

			audience
			from
			to
		}
	}
`;
