import { gql, useQuery } from "@apollo/client";
import React, { FC, useEffect, useState } from "react";
import { useFilters } from "shared-components/Components/Filters/Filters.hook";
import { SearchResultsPagination } from "../SearchResults/Content/SearchResultsCards/SearchResultsPagination/web";
import { ListingCard } from "./ListingCard";
import { useGoogleTagManager } from "shared-components/GlobalHooks/web/GoogleTagManager.hook";
import dynamic from "next/dynamic";
import { Empty } from "antd";
import Head from "next/head";
import getConfig from "next/config";
import { isSuperHighlighted } from "@Utils/Functions";
import { ListingStructDataJson } from "./ListingStructDataJson";
import { PropertyCardSkeleton } from "shared-components/Components/Property/PropertyCard/PropertyCardSkeleton";

const { NODE_ENV } = getConfig().publicRuntimeConfig;

//@ts-ignore
const BannerSponsorSilver = dynamic(() => import("../../Components/Banners/SponsorSilver/web").then(mod => mod.BannerSponsorSilver), { ssr: false });

type ListingResultsType = {
    returnPagination?: (pagination:any)=>void
}

export const Listings:FC<ListingResultsType> = ({returnPagination}) => {
    const { filters } = useFilters()
    const GTM = useGoogleTagManager()
    const { data, loading, error, fetchMore, refetch } = useQuery(QUERY_SEARCH_RESULTS_CARDS, {
		variables: {
			rows: RESULTS_PER_PAGE,
			params: filters,
			page: filters?.page,
			source: 0
		},
        fetchPolicy:"cache-first"
	})

    const [showSilverBanners, setShowSilverBanners] = useState<boolean>(false)
	const shouldShowSilverBanner = () => Math.round(Math.random() * 100) <= 40 

    useEffect(() => {
        setShowSilverBanners(shouldShowSilverBanner())
        
        returnPagination({
            info: data?.searchFast?.paginatorInfo,
            loading,error
        })

		if (data && data.length > 0) {
			GTM.Event({
				name: "searchresults",
				data: {
					listing_id: data.map(p => p.id),
					listing_totalvalue: data.map(p => p.price),
					listing_pagetype: "searchresults",
				},
			});
        }        
	}, [data]);

    let noIndex = false
    let noFollow = false
    if(
        data?.searchFast?.data && data?.searchFast?.data?.length == 0       ||
        filters?.neighborhood_id && filters?.neighborhood_id?.length >2     ||
        filters?.property_type_id && filters?.property_type_id?.length >2   ||
        filters?.owner_id                                                   ||
        filters?.bedrooms && filters?.bedrooms.length > 1                   ||
        filters?.order && [4,5].includes(filters?.order)
        ){
        console.warn('Page with: noindex, nofollow')
        noIndex = true
        noFollow = true
    }else if(data?.searchFast?.data && data?.searchFast?.data?.length <= 10){
        console.warn('Page with: nofollow')
        noIndex = false
        noFollow = true
    }else{
        noIndex = false
        noFollow = false
    }


    const printResults = (res) => {
        let holesPoints = 0
        return res?.map( (prop:any, i:number) => {
            const isBigCard = isSuperHighlighted(prop.highlight)
            holesPoints += isBigCard? 3 : 1
            const couldPrintBanner = holesPoints%9 == 0 // mod 9 es la cantidad de puntos en 3 filas (3 huecos x 3 filas)

            return <React.Fragment key={`lc-card-${prop.id}-${i}`}>
                <ListingCard data={prop} preloadImg={i<3} />
                { (couldPrintBanner && i != 0 && i<res?.length-1 && showSilverBanners) && 
                    <BannerSponsorSilver  /> 
                }
            </React.Fragment>
        })
    }

    return <>
        { (noIndex || noFollow) && <>
            <Head>
                <meta name="robots" content={ !noIndex ? 'nofollow' : 'noindex, nofollow'} />
            </Head>
        </>}

        {data?.searchFast?.data && data?.searchFast?.data?.length == 0 
            ? <Empty style={{ padding: "50px 0px" }} description={"No se encontraron resultados para su búsqueda."} />
            : <>
                <ListingStructDataJson listings={data?.searchFast?.data}/>
                <section className="listingsWrapper">
                    {loading && [...Array(6)].map((_,i) => <div className="listingCard" key={i}><PropertyCardSkeleton /></div> ) }
                    {!loading && data?.searchFast?.data && printResults(data?.searchFast?.data) }
                </section>
                <SearchResultsPagination error={error} loading={loading} paginatorInfo={data?.searchFast?.paginatorInfo}/>
            </>
        }
    </>
}


export const RESULTS_PER_PAGE = 21;
const QUERY_SEARCH_RESULTS_CARDS = gql`
    query ResultsGird_v2($rows: Int!, $params: SearchParamsInput!, $page: Int, $source: Int) {
        searchFast(params: $params, first: $rows, page: $page, source: $source) 
    }
`;