
import { gql } from "@apollo/client";

export const MUTATION_REQUEST_PHONE = gql`
    mutation mutation_request_phone(
        $id: Int!
        $type: RequestPhoneTypes!
        $isWpp: Boolean=false
        $context_id: Int
        $context_type: PropEntityType=PROPERTY
    ) {
        requestPhone_new(
            id: $id
            type: $type
            isWpp: $isWpp
            context_id: $context_id
            context_type: $context_type
        )
    }
`;