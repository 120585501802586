import "./styles.less";

import { AutoComplete, Col, Input, Row, Space, Typography } from "antd";
import { KeywordLocationProps, useKeywordLocation } from "./KeywordLocation.hook";
import React, { Suspense, useContext, useEffect, useRef, useState } from "react";

import { ConfigStateContext } from "../../../Contexts/Configurations/context";
import { encodeHashUrl } from "../../../Utils/Functions";
import { useRouter } from "next/router";
import { useTheme } from "../../../Styles/ThemeHook";
import { LocationIcon } from "shared-components/Components/CustomIcons/LocationIcon/web";
import { getTraduction } from "src/functions/functions";
import { firstLetterToUppercase } from "src/utils/Functions";
import useLocationExtraData from "src/hooks/locationExtraData/useLocationExtraData";

export const KeywordLocation = ({
	filterChanged,
	className,
	searchByProject = false,
	searchByReference = true,
	handleLocations,
	isSearchForHome = false,
	locations = [],
	changeKeyword = null,
}: KeywordLocationProps) => {
	const {
		show,
		keyword,
		onSearch,
		onSelect,
		setKeyWord,
		searchResults,
		refResults,
		searchLoading,
		projectsResults,
		location,
	} = useKeywordLocation({
		filterChanged,
		className,
		searchByProject,
		searchByReference,
		locations,
	});

	const ref = useRef(null);
	const [isOpen, setOpen] = useState(false);
	const { country_code, translations } = useContext(ConfigStateContext);
	const router = useRouter();
	const { theme } = useTheme();
	const { setLocationText } = useLocationExtraData();

	useEffect(() => {
		if (location) ref.current.blur();
	}, [location]);

	useEffect(() => {
		if (isSearchForHome) {
			handleLocations([]);
			if (searchResults?.searchLocation.length > 0 && keyword?.length > 0)
				handleLocations(searchResults.searchLocation);
			if (changeKeyword) {
				changeKeyword(keyword);
			}
		}
	}, [keyword, searchResults]);

	if (!show) return null;

	const onClearKeyWord = () => {
		setLocationText("");
		setKeyWord("");
		filterChanged({
			locations: [],
		})
	}

	const renderOption = ({ value, label, data, group = null, icon }) => {
		return {
			key: data.id,
			data: data,
			value: value,
			label: (
				<Row>
					{country_code != "BR" && (
						<Col style={{ marginRight: theme.spacing.smSpacing }}>{icon}</Col>
					)}
					<Typography.Text style={{ flex: "1", whiteSpace: "break-spaces" }}>
						<span dangerouslySetInnerHTML={{ __html: label }}></span>
					</Typography.Text>
					{(group && country_code !== "ce3") && (
						<Col style={{ marginLeft: theme.spacing.smSpacing }}>
							<Typography.Text type="secondary">{group}</Typography.Text>
						</Col>
					)}
				</Row>
			),
		};
	};

	const renderTitle = title => {
		return (
			<Row>
				<Typography.Text strong>{title}</Typography.Text>
			</Row>
		);
	};

	let options = [];
	let optionsData = [];
	let optionsTitle = "";

	// location options && recient searches
	if (searchResults?.searchLocation.length > 0 && keyword.length > 0) {
		optionsData = searchResults.searchLocation;
		optionsTitle = "Ubicación";
	} else if (keyword?.length === 0 && isOpen) {
		optionsData = getRecientLocations();
		optionsTitle = "Recientes";
	}

	// projects options
	if (
		searchByProject &&
		projectsResults?.searchListing &&
		projectsResults?.searchListing.length > 0 &&
		keyword.length > 0
	) {
		options = [
			...options,
			{
				label: renderTitle("Proyectos"),
				options: projectsResults.searchListing.data.map((p, i) => {
					return renderOption({
						label: <Typography.Text>{p.title}</Typography.Text>,
						value: p.title,
						data: p,
						icon: <Suspense fallback={<div>...</div>}>
							{React.createElement(React.lazy((async () => (await import("@ant-design/icons/HomeOutlined")))))}
						</Suspense>,
						group: "Proyecto",
					});
				}),
			},
		];
	}

	// reference options
	if (searchByReference && refResults?.searchByRef && keyword?.length >= 5) {
		options = [
			...options,
			{
				label: renderTitle("Código de Referencia"),
				options: [
					renderOption({
						label: <Typography.Text>{refResults.searchByRef.title}</Typography.Text>,
						value: refResults.searchByRef.id,
						data: refResults.searchByRef,
						icon: <Suspense fallback={<div>...</div>}>
							{React.createElement(React.lazy((async () => (await import("@ant-design/icons/KeyOutlined")))))}
						</Suspense>,
					}),
				],
			},
		];
	}

	// keyword option
	// if (!searchLoading && keyword?.length > 0) {
	// 	options = [
	// 		...options,
	// 		{
	// 			label: renderTitle("Palabra Clave"),
	// 			options: [
	// 				renderOption({
	// 					label: "Buscar inmuebles relacionados a" + " " + keyword,
	// 					value: " " + keyword,
	// 					data: { label: keyword, __typename: "keyword" },
	// 					icon: <Suspense fallback={<div>...</div>}>
	// 						{React.createElement(React.lazy((async () => (await import("@ant-design/icons/SearchOutlined")))))}
	// 					</Suspense>,
	// 				}),
	// 			],
	// 		},
	// 	];
	// }

	if (!searchLoading && keyword?.length > 0) {
		options = [
			...options,
			{
				label: renderTitle("No hay resultados"),
				options: [
					renderOption({
						label: "No hay resultados",
						value: " ",
						data: { label: keyword, __typename: "empty" },
						icon: <Suspense fallback={<div>...</div>}>
							{React.createElement(React.lazy((async () => (await import("@ant-design/icons/SearchOutlined")))))}
						</Suspense>,
					}),
				],
			},
		];
	}

	if (optionsData.length > 0) {
		options = [
			{
				label: renderTitle(optionsTitle),
				options: optionsData.map(o => {
					const label = getOptionLabel(o)
					o.__typename == "Neighborhood" ? o.name + ", " + o.estate.name : o.name;
					const group = country_code !== "CO" ? o.__typename ? getTraduction(o.__typename.toLowerCase(), country_code) : getTraduction(o.type.toLowerCase(), country_code) : "";

					const icon = (
						<Suspense fallback={<div>...</div>}>
							{React.createElement(React.lazy((async () => (await import("@ant-design/icons/EnvironmentOutlined")))))}
						</Suspense>
					);
					return renderOption({
						value: label.replace(/<\/?[^>]+(>|$)/g, ""),
						label,
						data: { ...o, label: label },
						icon,
						group,
					});
				}),
			},
		];
	}

	return (
		<AutoComplete
			className={"filter keyword-location " + className}
			ref={ref}
			backfill
			allowClear
			onClear={onClearKeyWord}
			clearIcon={
				<Suspense fallback={<div>...</div>}>
					<div onClick={onClearKeyWord}>
						{React.createElement(React.lazy((async () => (await import("@ant-design/icons/CloseOutlined")))))}
					</div>
				</Suspense>}
			value={keyword}
			options={options}
			notFoundContent={
				<Space style={{ padding: `${theme.spacing.xsSpacing}px 0` }}>
					{keyword != "" && <Suspense fallback={<div>...</div>}>
						{React.createElement(React.lazy((async () => (await import("@ant-design/icons/LoadingOutlined")))))}
					</Suspense>}
					<Typography.Text type={"secondary"}>
						{keyword != "" ? "Cargando" : "Escribe una ubicación"}
					</Typography.Text>
				</Space>
			}
			dropdownClassName={
				"dropdown-keyword-location" + (className ? ` ${className}-dropdown` : "")
			}
			onSearch={onSearch}
			onSelect={(a, b) => {
				if (b.data?.__typename === "empty") return
				if (b.data.__typename == "Property") {
					let data = {
						...b.data,
						pathname: "/propSingle",
						link: b.data.link,
						avoid: false,
					};

					if (b.data.project.length > 0) {
						data.pathname = "/projectSingle";
						data.void = b.data.project[0].isEspecial;
						data.link = b.data.project[0].link;
						data.id = b.data.project[0].id;
					}

					if (b.data.isExternal) {
						window.open(
							data.link,
							"_blank",
							"location=yes,height=670,width=620,scrollbars=yes,status=yes"
						);
					} else if (data.avoid) {
						window.location = data.link;
					} else {
						router.push(
							{
								pathname: data.pathname,
								query: { hashed: encodeHashUrl(data) },
							},
							"/" + data.link
						);
					}
				} else if (b.data.__typename == "Project") {
					let data = {
						pathname: "/projectSingle",
						void: b.data.isEspecial,
						link: b.data.link,
						id: b.data.id,
					};

					router.push(
						{
							pathname: data.pathname,
							query: { hashed: encodeHashUrl(data) },
						},
						"/" + data.link
					);
				} else {
					onSelect(a, b.data);
				}
			}}
			onFocus={onClearKeyWord}
			onBlur={e => {
				if (location) onSearch(location.label);
			}}
			onDropdownVisibleChange={e => setOpen(e)}
			placeholder={country_code === "ce3" ? <><LocationIcon /> Ubicación</> : `🔍︎ ${firstLetterToUppercase(translations.busca)} por ubicación`}
		>
			<Input
			// onPressEnter={() => {
			// 	if (keyword) onSelect(keyword, { label: keyword, __typename: "keyword" });
			// }}
			/>
		</AutoComplete>
	);
};

export const LOCAL_STORAGE_KEY = "RecientLocations";
const getRecientLocations = () => {
	const items = localStorage?.getItem(LOCAL_STORAGE_KEY);
	let res = [];
	if (items) res = JSON.parse(items);
	return res;
};

export const setRecientLocations = (neighborhood, estate) => {
	if (estate) {
		let location = {};
		location["id"] = estate["value"];
		location["name"] = estate["text"];
		location["__typename"] = "Estate";
		if (neighborhood && neighborhood.length > 0) {
			location["estate"] = { ...location };
			location["id"] = neighborhood[0]["value"];
			location["name"] = neighborhood[0]["text"];
			location["__typename"] = "Neighborhood";
		}
		let res = getRecientLocations();
		res = res.filter(l => l.id != location["id"] || l["__typename"] != location["__typename"]);
		res = [{ ...location }, ...res];
		if (res.length > 5) res.pop();
		localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(res));
	}
};

export const getOptionLabel = (option) => {
	let label = ""

	label = option.name;

	if (option.type === "STATE") label += ` <br/><span style='font-size:12px'>${option.country[0].name}</span>`
	else if (option.type === "NEIGHBOURHOOD") label += ` <br/><span style='font-size:12px'>${(option.city && !!option.city.length) ? (option.city[0].name) : ""}${option.estate ? (", " + option.estate.name) : ""}${option.zone && !!option.zone.length ? (", " + option.zone[0].name) : ''}</span>`
	else if (option.type === "CITY") label += ` <br/><span style='font-size:12px'>${option.estate.name}</span>`
	else label += ` <br/><span style='font-size:12px'>${(option.city && !!option.city.length) ? (option.city[0].name) : ""}${option.estate ? (", " + option.estate.name) : ""}</span>`
	return label
}