import { gql } from "@apollo/client";

export const QUERY_LEAD_REPLIES = gql`
	query getLeadReplies($id: ID!, $first: Int!, $page: Int) {
		leadReplies(id: $id, first: $first, page: $page) {
			data {
				id
				message
				created_at
				sender
				seen
			}
			paginatorInfo {
				lastPage
				total
				count
				currentPage
			}
		}
	}
`;
