import './styles.less'

export const Tooltip = ({
    children,
    text="",
    position="top",
    className='',
    style=null
}) => {

    return(
        <div className={`tooltip-trigger ${className}`} style={style}>
            {children}
            <div className={`tooltip tooltip-${position}`}>
                {text}
            </div>
        </div>
    )
}