import { cleanHtmlFromString, cleanJumpsHtmlFromString } from "../../../Utils/Functions";
import { FragmentDefiner } from "../../../GlobalHooks/useReadFragment";

export const FRAGMENT_DESCRIPTION = new FragmentDefiner(
	"Property",
	`
    description
    project {
        description
    }
`
);

export interface DescriptionProps {
	description: string;
	limitLength?: number;
	hideInformation?: boolean;
	expandable?: boolean;
	keepLineBreaks?: boolean;
	keepFormat?: boolean;
	showTitle?: boolean;
	loading?: boolean;
	cleanHtml?: boolean;
}

export const useDescription = ({
	limitLength = 3,
	hideInformation = false,
	expandable = false,
	keepLineBreaks = false,
	keepFormat = false,
	description,
	showTitle = false,
	loading = false,
	cleanHtml = false,
}: DescriptionProps) => {
	let htmlString = cleanHtml ? cleanHtmlFromString(description, keepLineBreaks) : description;
	if (keepFormat) htmlString = cleanJumpsHtmlFromString(description);

	return {
		limitLength,
		hideInformation,
		expandable,
		keepLineBreaks,
		description: htmlString,
		showTitle,
		loading,
		cleanHtml,
	};
};
