import { useLayoutEffect, useState } from "react"


export const useWindowSize = () => {
    const [size, setSize] = useState({
      width: null,
      height: null,
    })
  
    if( typeof window !== 'undefined' ){
      useLayoutEffect(() => {
        const handleResize = () => {
          setSize({
            width: window.innerWidth,
            height: window.innerHeight,
          })
        }
        
        handleResize()
        window.addEventListener("resize", handleResize)
        
        return () => {
          window.removeEventListener("resize", handleResize)
        }
      }, [])
    }
  
    return {
        viewportWidth: size.width,
        viewportHeight: size.height,

        xs: size.width ? size.width <= 576 : null,
        sm: size.width ? size.width >= 576 : null,
        md: size.width ? size.width >= 768 : null,
        lg: size.width ? size.width >= 992 : null,
        xl: size.width ? size.width >= 1200 : null,
        xxl: size.width ? size.width >= 1600 : null,
    }
}