
export const HeartIcon = ({ width = 20, fillOpacity = 0.5, fillColor = "black", strokeColor = "currentColor" }) => {
	return (
		<svg
			width={width}
			height={width}
			viewBox="0 0 27 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.7003 3.73684L13.5 4.80182L14.2997 3.73684C15.4721 2.17541 17.2794 1.23697 19.2417 1.23697C22.6641 1.23697 25.5 4.11233 25.5 7.70749C25.5 9.49728 24.8053 11.1548 23.5755 12.3727L23.5677 12.3804L23.5601 12.3883L13.5192 22.7787L13.5189 22.7791L13.5 22.7986L13.481 22.779L13.4808 22.7787L3.24993 12.1937C2.12199 10.9811 1.5 9.39031 1.5 7.70749C1.5 4.11233 4.33588 1.23697 7.75833 1.23697C9.72062 1.23697 11.5279 2.17541 12.7003 3.73684Z"
				fill={fillColor}
				fillOpacity={fillOpacity}
				stroke={strokeColor}
				strokeWidth="2"
			/>
		</svg>
	)
};

