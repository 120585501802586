import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { FRAGMENT_PROPERTY_CARD } from "../../Property/PropertyCard/PropertyCard.hook";
import { isValidCharacters } from "../../../Utils/Functions";
import { useDebouncedCallback } from "../../../GlobalHooks/useDebounce";
import { getLocations } from "src/services/search";
import useLocationExtraData from "src/hooks/locationExtraData/useLocationExtraData";

const KEYWORD_LOCATION_QUERY = gql`
	query Location($strSearch: String!) {
		searchLocation(searchTerm: $strSearch) {
			... on Estate {
				id
				name
			}
			... on Neighborhood {
				id
				name
				estate {
					id
					name
				}
			}
			__typename
		}
	}
`;

const PROJECTS_COUNT = 3;
const PROJECTS_QUERY = gql`
	query SearchProject($title: String) {
		searchListing(params: { operation_type_id: 1, projects:true, projectTitle: $title }, first: ${PROJECTS_COUNT}, page: 1) {
			data {
				${FRAGMENT_PROPERTY_CARD.query()}
			}
		}
	}
`;

const REF_QUERY = gql`
	query searchByRef($code: String!) {
		searchByRef(refCode: $code) {
			... on Project {
				id
				title
				link
				isEspecial
			}
			... on Property {
				${FRAGMENT_PROPERTY_CARD.query()}
			}
		}
	}
`;

export interface KeywordLocationProps {
	className?: string;
	searchByProject?: boolean;
	searchByReference?: boolean;
	isSearchForHome?: boolean;
	filterChanged: ({ }: any) => void;
	handleLocations?: ([]) => void;
	locations?: any;
	changeKeyword?: any;
}

export const useKeywordLocation = ({
	filterChanged,
	searchByReference,
	searchByProject,
	locations = [],
}: KeywordLocationProps) => {
	const { locationText, setLocationText, setCoordinates } = useLocationExtraData();
	const [location, setLocation] = useState(null);
	const [newData, setNewData] = useState(null);
	const [debounceTimeOut, setDebounceTimeOut] = useState(null);
	const [keyword, setKeyWord] = useState<string>(locationText);

	useEffect(() => updateFilters(), [location]);

	useEffect(() => {
		if (debounceTimeOut != null) {
			clearTimeout(debounceTimeOut);
		}
		if (keyword?.length > 1) {
			setDebounceTimeOut(
				setTimeout(() => {
					getLocations(keyword)
						.then(data => {
							setNewData(data);
						})
						.catch(e => {
							console.log(e);
						});
				}, 500)
			);
		}
	}, [keyword]);

	useEffect(() => {
		setKeyWord(locationText)
	}, [locationText])

	// hook functions
	const onSelect = (val, opt) => {
		setLocationText(val.replace(/<[^>]*>/g, ' '))
		setLocation(opt);
		setKeyWord(val.replace(/<[^>]*>/g, ' '));
	};

	const onSearch = (val: string) => {
		val = val.replace(/<[^>]*>/g, ' ');
		if (isValidCharacters(val) || val == "") {
			setKeyWord(val);
			search(val);
		}
	};

	const [search] = useDebouncedCallback((val: string) => {
		if (val.length > 0) {
			searchQuery({ variables: { strSearch: val } });
		}
		if (searchByProject && val.length > 0) projectQuery({ variables: { title: val } });
		if (searchByReference && val.length >= 5) refQuery({ variables: { code: val } });
	}, 500);

	const [searchQuery, { data, loading }] = useLazyQuery(KEYWORD_LOCATION_QUERY);
	const [projectQuery, { data: dataProjects, loading: loadingProjects }] = useLazyQuery(
		PROJECTS_QUERY
	);

	const [refQuery, { data: dataRef, loading: loadingRef }] = useLazyQuery(REF_QUERY);

	const updateFilters = () => {
		if (location) {
			if (location.__typename == "keyword") {
				if (locations.length > 0) {
					const firstLocation = locations[0];

					filterChanged({
						locations: [
							{
								text: "locations",
								value: firstLocation,
							},
						],
						searchstring: null,
					});

					setCoordinates({
						lat: firstLocation.location_point.coordinates[1],
						lng: firstLocation.location_point.coordinates[0],
					})
				} else {
					filterChanged({
						locations: [],
						searchstring: { text: "Palabra Clave: " + keyword, value: keyword },
					});
				}
			} else if (location.type) {
				filterChanged({
					locations: [
						{
							text: "locations",
							value: location,
						},
					],
					searchstring: null,
				});

				setCoordinates({
					lat: location.location_point.coordinates[1],
					lng: location.location_point.coordinates[0],
				})
			} else {
				filterChanged({
					searchstring: null,
					locations: [],
				});
			}
		}
	};

	// hook output API
	return {
		keyword,
		setKeyWord,
		location,
		show: true,
		onSearch,
		onSelect,
		searchResults: newData,
		refResults: dataRef,
		projectsResults: dataProjects,
		searchLoading: loading || loadingRef || loadingProjects,
	};
};

