import "./styles.less";
import { useSearchResultsPagination } from "./SearchResultsPagination.hook";
import { useRouter } from "next/router";
import { useContext } from "react";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { SearchResultDisplay } from "./ResultDisplay/web";

export const SearchResultsPagination = ({
	error,
	loading,
	paginatorInfo,
	initialUrlUpdate,
	genUrlType,
	explicitFilters,
}) => {
	const {
		changePage,
		data: { currentPage, perPage, total, lastPage },
	} = useSearchResultsPagination(paginatorInfo, false, initialUrlUpdate, explicitFilters);
	const { country_code } = useContext(ConfigStateContext);
	const { asPath } = useRouter();

	if (error || loading) return null;

	const genPagURL = page => {
		// Función para generar la URL de la página
		if (genUrlType === "query.pagina") {
			return updateQueryString(asPath, "pagina", page);
		} else {
			return buildPathUrl(asPath, page, total, perPage);
		}
	};

	return (
		<div className="search-results-pagination">
			<SearchResultDisplay error={error} loading={loading} paginatorInfo={paginatorInfo} />
			<ul className="list" role="navigation" aria-label="Pagination">
				{/* Botón de página anterior */}
				{currentPage > 1 && (
					<PaginationButton
						page={currentPage - 1}
						genPagURL={genPagURL}
						onClick={changePage}
						country_code={country_code}
						label="<"
					/>
				)}

				{/* Genera los elementos de la paginación */}
				{generatePaginationArray(lastPage, currentPage).map((page, i) => (
					<PaginationItem
						key={i}
						page={page}
						currentPage={currentPage}
						genPagURL={genPagURL}
						changePage={changePage}
						country_code={country_code}
					/>
				))}

				{/* Botón de página siguiente */}
				{currentPage < lastPage && currentPage < 50 && (
					<PaginationButton
						page={currentPage + 1}
						genPagURL={genPagURL}
						onClick={changePage}
						country_code={country_code}
						label=">"
					/>
				)}
			</ul>
		</div>
	);
};

const updateQueryString = (url, key, value) => {
	// Función para generar la URL cuando se usa query string
	const reg = new RegExp(`[?&]${key}=([^&#]*)`, "i");
	const queryString = reg.exec(url);
	if (queryString) {
		return url.replace(`${key}=${queryString[1]}`, `${key}=${value}`);
	}
	return `${url}${url.includes("?") ? "&" : "?"}${key}=${value}`;
};

const buildPathUrl = (asPath, page, total, perPage) => {
	// Función para generar la URL basada en el path (no query string)
	const [urlSegments, paramString] = asPath.split("?");
	let basePath = urlSegments
		.replace(/pagina([0-9]+)?\/?$/gm, "")
		.split("/")
		.filter(Boolean);
	const totalPages = Math.ceil(total / perPage);

	if (page > 1 && page <= totalPages) basePath.push(`pagina${page}`);

	let newPagURL = `/${basePath.join("/")}`;
	return paramString ? `${newPagURL}?${paramString}` : newPagURL;
};

const PaginationButton = (
	{ page, genPagURL, onClick, country_code, label } // Componente para el botón de paginación (anterior/siguiente)
) => (
	<li className={`ant-pagination-item ${country_code}`}>
		<a
			className={`ant-pagination-item-link ${country_code}`}
			href={genPagURL(page)}
			rel="nofollow"
			onClick={e => {
				e.preventDefault();
				window.scrollTo(0, 0);
				onClick(page);
			}}>
			{label}
		</a>
	</li>
);

const PaginationItem = ({ page, currentPage, genPagURL, changePage, country_code }) => {
	// Componente para cada item de paginación (número de página)
	const isDotts = page === "...";
	const cssClass = `ant-pagination-item ${country_code} ${
		!isDotts && currentPage === page ? "ant-pagination-item-active" : ""
	}${isDotts ? "dots-link" : ""}`;

	return (
		<li className={cssClass}>
			{isDotts ? (
				<span className={`ant-pagination-item-link ${country_code}`} aria-hidden="true">
					{page}
				</span>
			) : currentPage === page ? (
				<span
					className={`ant-pagination-item-link ${country_code}`}
					aria-label="current page">
					{page}
				</span>
			) : (
				<a
					className={`ant-pagination-item-link ${country_code}`}
					href={genPagURL(page)}
					aria-label={`Page ${page}`}
					rel="nofollow"
					onClick={e => {
						e.preventDefault();
						window.scrollTo(0, 0);
						changePage(page);
					}}>
					{page}
				</a>
			)}
		</li>
	);
};

const generatePaginationArray = (totalPages, currentPage) => {
	// Función para generar el array de paginación
	let res = [];

	const totalPagesNormalized = totalPages > 50 ? 50 : totalPages;

	currentPage = Math.min(Math.max(1, currentPage), totalPagesNormalized);

	if (totalPagesNormalized <= 12) {
		for (let i = 1; i <= totalPagesNormalized; i++) {
			res.push(i);
		}
		return res;
	}

	// Lógica si estamos al inicio de la paginación
	if (currentPage <= 6) {
		for (let i = 1; i <= 7; i++) {
			res.push(i);
		}
		res.push("...");
		res.push(totalPagesNormalized);
	}
	// Lógica si estamos al final de la paginación
	else if (currentPage >= totalPagesNormalized - 4) {
		res.push(1); // Primera página
		res.push("...");
		for (let i = totalPagesNormalized - 6; i <= totalPagesNormalized; i++) {
			res.push(i);
		}
	}
	// Si estamos en el medio de la paginación
	else {
		res.push(1);
		res.push("...");
		for (let i = currentPage - 2; i <= currentPage + 2; i++) {
			res.push(i);
		}
		res.push("...");
		// Agregar la última página
		res.push(totalPagesNormalized);
	}

	return res;
};

