import { firstLetterToUppercase } from "src/utils/Functions"
import "./styles.less"
import { useContext } from "react";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";

export const AppLinksCeee = () => {
    const { translations } = useContext(ConfigStateContext);

    return (
        <div className="app-ceee-links">
            <img className="foca-image" src="https://cdn2.infocasas.com.uy/web/62ec369405517_foquitafooter.png" alt="Mascota CEEE" />
            <div className="app-ceee-links_content">
                <h5 className="app-title">
                    {firstLetterToUppercase(translations.descarga)} la app
                    de Casas en el Este
                </h5>
                <a
                    title="App Store"
                    href="https://itunes.apple.com/uy/app/casas-en-el-este/id1596161064?mt=8"
                    target="_blank"
                    className="app-button"
                    rel="nofollow"
                >
                    <img src="https://cdn2.infocasas.com.uy/web/62e1dbd6828ba_logo_appstore.png" alt="App Store" />
                </a>
                <a
                    title="Playstore"
                    href="https://play.google.com/store/apps/details?id=uy.com.casaseneleste.infoapp"
                    target="_blank"
                    className="app-button"
                    rel="nofollow"
                >
                    <img src="https://cdn2.infocasas.com.uy/web/62e1dbd6862db_logo_playstore.png" alt="Playstore" />
                </a>
            </div>
        </div>
    )
}