import { gql } from "@apollo/client";

export const QUERY_LEAD_BY_ID = gql`
	query getLeadReplies($id: ID!) {
		leadById(id: $id) {
			id
			message
			created_at
			sender_id
			lastReply {
				message
				created_at
				sender
				seen
			}
			listing {
				__typename
				... on Property {
					id
					title
					img
				}
				... on Project {
					id
					title
					images {
						id
						image
					}
					link
					isEspecial
				}
			}
		}
	}
`;
