import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import Head from 'next/head';
import throttle from 'lodash/throttle';

const RoutesContext = createContext(null);

const useRoutesContext = () => {
  const context = useContext(RoutesContext);

  if (context === undefined) {
    throw new Error(
      'useRoutesContext must be used within a RoutesProvider',
    );
  }

  return context;
};

const RoutesProvider = ({ children }) => {

  const [routes, setRoutes] = useState({ loading: false });

  const customSetRoutes = (x) => {
    setRoutes(x)
  }

  useEffect(() => {
  }, [routes])

  return (
    <>
      <RoutesContext.Provider value={{ routes, setRoutes: customSetRoutes }}>
        {children}
      </RoutesContext.Provider>
    </>
  );
};

export { useRoutesContext };
export default RoutesProvider;
