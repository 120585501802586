import "./styles.less";

import React, { MouseEvent, useContext, useState } from "react";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
// import { useGoogleAnalytics } from "shared-components/GlobalHooks/web/GoogleAnalytics.hook";
import { useIsAuthModalShowing } from "../../../../../GlobalHooks/useIsAuthModalShowing";
import { Button } from "shared-components/Components/Buttons/web";
import { MessageIcon } from "shared-components/Components/CustomIcons/MessageIcon/web";

import dynamic from "next/dynamic"
import { firstLetterToUppercase } from "src/utils/Functions";
const ModalConsulta = dynamic(() => import("./ModalConsulta").then(mod => mod.ModalConsulta), { ssr: false });

export const FormButton = ({
	id,
	size = "middle",
	type = "primary",
	defaultMessage = `Hola, vi esta propiedad y quiero que me contacten. Gracias.`,
	isDisabledProperty = false,
	isInListing = false,
	sizeCard = "default",
	idealFilters,
	isProject,
	isDark,
	projectId
}: {
	id: string;
	size?: any;
	type?: any;
	defaultMessage?: string;
	isDisabledProperty?: boolean;
	isInListing?: boolean;
	sizeCard?: string;
	idealFilters?: any;
	isProject?: boolean;
	isDark?: boolean;
	projectId?: string;
}) => {
	const { isAuthModalShowing } = useIsAuthModalShowing();
	const [modal, setModal] = useState(false);
	const [hasBeenOpen, setHasBeenOpen] = useState(false)
	const { country_code, site_name, translations } = useContext(ConfigStateContext);

	const clickHandler = (e: MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();
		setModal(true);
		setHasBeenOpen(true);

		if (idealFilters) localStorage.setItem("propertyIdealFilters", JSON.stringify(idealFilters));
	};


	if (country_code !== "UY") defaultMessage = `Hola, vi esta propiedad en ${site_name} y quiero que me contacten. Gracias.`

	return (
		<>
			<Button onClick={e => clickHandler(e)} style={{ width: "100%" }} className={`btn-secondary${isDark ? ' btn-dark' : ''}`} type="noStyles">
				<MessageIcon /> {firstLetterToUppercase(translations.contactar)}
			</Button>

			{hasBeenOpen &&
				<ModalConsulta
					defaultMessage={defaultMessage}
					modal={modal}
					isAuthModalShowing={isAuthModalShowing}
					closeModal={() => setModal(false)}
					onSuccess={() => setModal(false)}
					id={id}
					isInListing={isInListing}
					isProject={isProject ?? false}
					projectId={projectId}
				/>
			}
		</>
	);
};


