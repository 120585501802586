import { CSSProperties, MouseEventHandler } from 'react'
import './styles.less'

type ButtonType = {
    type?: "primary" | "secondary" | "filledSecondary" | "noStyles"
    href?: string
    className?: string
    style?: CSSProperties
    onClick: MouseEventHandler<HTMLAnchorElement|HTMLButtonElement>
    children: any
    ref?: React.MutableRefObject<any>,
    disabled?: boolean
}
export const Button = ({type="primary", href, className, style,onClick,children, ref, disabled=false }:ButtonType) => {
    const classString = `button button-${type} ${className || '' }`

    return ( href
        ? <a href={href} className={classString} style={style} onClick={onClick} ref={ref} >{children}</a>
        : <button className={classString}style={style} onClick={onClick} ref={ref} disabled={disabled} >{children}</button>
    )
        
    
}