import { useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { useFilters } from "../../../../Components/Filters/Filters.hook";
import { encodeHashUrl } from "../../../../Utils/Functions";
import { useRouter } from "next/router";
import { QUERY_AVAILABLE_FILTERS } from "shared-components/GQL";
import { getFormattedFilters } from "src/utils/Functions";
import { useRoutesContext } from "src/contexts/RoutesContext";

const QUERY_SEARCH_URL = gql`
	query searchUrl($params: SearchParamsInput!) {
		searchUrl(params: $params) {
			url
		}
	}
`;

const useSearchResultsFilters = (forceUrlUpdate = true, initialUrlUpdate = true) => {
	const { filters, changeFilters, filtersTags } = useFilters();
	const [updateURL, setUpdateURL] = useState(initialUrlUpdate);
	const RoutesContext = useRoutesContext();
	const router = useRouter();

	const [getSearchURL, response] = useLazyQuery(QUERY_SEARCH_URL, {
		variables: {
			params: getFormattedFilters(filters) ?? {},
		},
		skip: !updateURL && !forceUrlUpdate,
		onCompleted: dataURL => {
			if (typeof window !== "undefined" && window.history && updateURL && dataURL.searchUrl.url != window.SUPER_VARIABLE) {
				if (typeof window.SUPER_VARIABLE !== "undefined") {
					router.push({
						pathname: "/searchPage",
						query: { hashed: encodeHashUrl({ filters: filtersTags }) },
					}, dataURL.searchUrl.url)
					RoutesContext.setRoutes({ loading: true })
				}
				window.SUPER_VARIABLE = dataURL.searchUrl.url
			}
		},
	});

	const changeFiltersAndUpdateURL = async (new_filters) => {
		if (!updateURL) setUpdateURL(true);
		await getSearchURL();
		changeFilters(new_filters);
	};

	const { data, loading, error } = useQuery(QUERY_AVAILABLE_FILTERS);

	return {
		data,
		loading,
		error,
		changeFiltersAndUpdateURL,
	};
};

export { useSearchResultsFilters };
