import { formatTagEventDate } from "src/functions/dateFormats"
import "./styles.less"

type Event = {
    tag: string
    show: boolean,
    link: string,
    logo: string,
    fecha_inicio_evento: string
}

export const TagEvent = ({ event }: { event: Event }) => {
    return (
        <span className="container-tag-event">
            <a className="container-tag-event-box" href={event.link} target="_blank">
                <img src={event.tag} className="tag-event-image" />
                <span className="date">{formatTagEventDate(event.fecha_inicio_evento)}</span>
            </a>
        </span>
    )
}