export const LocationIcon = ({ width = 20 }) => (
	<svg
		width={width}
		height={width}
		viewBox="0 0 275 396"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M137.318 0C61.6 0 0 61.609 0 137.329C0 210.216 124.591 380.506 129.896 387.717L134.847 394.455C135.426 395.247 136.348 395.71 137.318 395.71C138.303 395.71 139.219 395.247 139.804 394.455L144.752 387.717C150.06 380.506 274.648 210.216 274.648 137.329C274.648 61.609 213.038 0 137.318 0ZM137.318 88.138C164.448 88.138 186.509 110.2 186.509 137.329C186.509 164.444 164.447 186.52 137.318 186.52C110.204 186.52 88.127 164.444 88.127 137.329C88.127 110.2 110.203 88.138 137.318 88.138Z"
			fill="currentColor"
		/>
	</svg>
);
