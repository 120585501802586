import { useContext, useEffect, useState } from "react";

import { UserDispatchContext } from "../../../Contexts/User/context";
import { diff } from "deep-diff";
import gql from "graphql-tag";
import { useFilters } from "../../Filters/Filters.hook";
import { useMutation } from "@apollo/client";
import { useUser } from "../../User/User.hook";
import { FiltersValues } from "shared-components/Contexts/Filters/model";

export const sameSearch = (s1, s2) => {
	const same = diff(s1, s2, {
		normalize: (path, key, hls, hrs) => {
			if (key === "currencyID") {
				if (!s1.minPrice && !s1.maxPrice) hls = 1;
				if (!s2.minPrice && !s2.maxPrice) hrs = 1;
				return [hls, hrs];
			}

			if (key === "m2Currency") {
				if (!s1.minM2Price && !s1.minM2Price) hls = 1;
				if (!s2.maxM2Price && !s2.maxM2Price) hrs = 1;
				return [hls, hrs];
			}

			if (["page", "order", "mapView"].find((e) => e == key)) return [1, 1];

			if (
				typeof hls == "undefined" &&
				(hrs === null || (typeof hrs == "object" && hrs.length == 0))
			) return [1, 1];

			if (!isNaN(hls)) hls = String(hls);
			if (!isNaN(hrs)) hrs = String(hrs);

			return [hls, hrs];
		},
	})

	return !same;
}

const FRAGMENT_DATA = `
    id
    ...on Individual {
        searchAlerts(status: 1) {
            id
            filters
            status
        }
    }
`;

const MUTATION_TOGGLE_SEARCH_ALERT = gql`
    mutation toggleSearchAlert($search: SearchParamsInput!, $frequency: Int!, $type: Int!) {
        toggleSearchAlert(search: $search, frequency: $frequency, type: $type) {
            ${FRAGMENT_DATA}
        }
    }
    
`;

export const useCreateSearchAlert = () => {
	const { user: { data, loading } } = useUser();
	const userDispatch = useContext(UserDispatchContext)

	const { filters } = useFilters();

	const [mutation, response] = useMutation(MUTATION_TOGGLE_SEARCH_ALERT, {
		onError: () => { },
	});

	const [alertIsSaved, setAlertIsSaved] = useState(false);

	useEffect(() => {
		if (data?.me) {
			const isSaved = data.me.searchAlerts?.reduce((acc, curr) => {
				return acc || sameSearch(curr.filters, filters);
			}, false);

			setAlertIsSaved(isSaved);
		} else {
			setAlertIsSaved(false);
		}
	}, [data, filters]);

	const createSearchAlert = async (searchType: number) => {
		const res = await mutation({
			variables: {
				frequency: 24, // es fijo
				search: filters,
				type: searchType,
			},
		});

		if (res?.data) {
			userDispatch({
				type: "merge",
				payload: { searchAlerts: res.data.toggleSearchAlert.searchAlerts }
			});
		}

		return res
	};

	const customSearchAlert = async (searchType: number, description: string, idealFilters?: FiltersValues) => {
		const filtersToUse = idealFilters || filters;

		const res = await mutation({
			variables: {
				frequency: 24, // es fijo
				search: {
					description,
					...filtersToUse
				},
				type: searchType,
			},
		});

		if (res?.data) {
			userDispatch({
				type: "merge",
				payload: { searchAlerts: res.data.toggleSearchAlert.searchAlerts }
			});
		}

		return res
	}

	return {
		searchAlert: { send: createSearchAlert, response: response, customForm: customSearchAlert },
		alertIsSaved,
		loading,
	};
};
