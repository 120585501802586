import dayjs from "dayjs";
import "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import { FragmentDefiner } from "../../../GlobalHooks/useReadFragment";

export const FRAGMENT_PUBLICATIONTIME = new FragmentDefiner(
	"Property",
	`
    created_at
    updated_at
`
);

export const usePublicationTime = ({ updated_at, created_at }) => {
	let text = "";

	const thresholds = [
		{ l: "d", r: 1 },
		{ l: "ay", r: 2, d: "day" },
		{ l: "dd", r: 29, d: "day" },
		{ l: "M", r: 1 },
		{ l: "MM", r: 11, d: "month" },
		{ l: "y" },
		{ l: "yy", d: "year" },
		{ l: "yy", d: "year" },
	];

	dayjs.locale("es");
	dayjs.extend(relativeTime, { thresholds });
	dayjs.extend(updateLocale);

	dayjs.updateLocale("es", {
		relativeTime: {
			past: "%s",
			d: "hoy",
			ay: "ayer",
			dd: "hace %d dias",
			M: "hace 1 mes",
			MM: "hace %d meses",
			y: "hace un año",
			yy: "hace %d años",
		},
	});

	if (typeof updated_at != "undefined" && typeof created_at != "undefined") {
		if (dayjs(updated_at).isValid() && dayjs(updated_at).isAfter(created_at)) {
			text = "Actualizada " + dayjs(updated_at).fromNow(true);
		} else {
			text = "Publicada " + dayjs(created_at).fromNow(true);
		}
	}

	return {
		text,
	};
};
