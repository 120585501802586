import { UserBox } from "shared-components/ViewFragments/Header/UserBox/web";
import { HeaderMenu } from "./HeaderMenu"
import { useWindowSize } from "shared-components/GlobalHooks/useWindowSize";
import { CloseIcon } from "shared-components/Components/CustomIcons/CloseIcon/web";

export const DrawerMobile = ({ visible, onClose }) => {
	const screens = useWindowSize()

	return (
		<>
			<div
				className={`mobileMenu-overlay ${visible ? "visible" : ""}`}
				onClick={onClose}
			></div>
			<div className={`mobileMenu-drawer ${visible ? "visible" : ""}`}>
				<div className="container-closeBtn">
					<span className="subhead subhead-semibold">Menú</span>
					<span className="closeBtn" onClick={onClose}>
						<CloseIcon width={10} />
					</span>
				</div>
				<HeaderMenu type="mobile" />
				{!screens.xl && !screens.lg && <UserBox isMobile />}
			</div>
		</>
	);
};