import "./styles.less";

import { ImagenOptimizada as Img } from "../../../../Components/Image/_new_web";
import { removeUrlProtocol } from "../../../../Utils/Functions";
import { useCountries } from "./hook";


export const Flags = () => {
	const { countries } = useCountries();

	return (
		<div className="flags">
			{countries.filter(country => country.order != 0).map(country => (
				<a key={"flags" + country.id} href={removeUrlProtocol(country.url)} title={country.name}>
					<Img src={country.country_flag} alt={country.name} />
				</a>
			))}
		</div>
	);
};
