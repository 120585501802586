import { useContext, useEffect, useState } from "react";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";

export const useInstitucional = () => {
	const { country_code } = useContext(ConfigStateContext);
	const [data, setData] = useState([
		{
			id: 2,
			name: "Preguntas Frecuentes",
			url: "/informacion#preguntas-frecuentes",
		},
		{
			id: 5,
			name: "Aviso Legal",
			url: "/informacion#aviso-legal",
		},
		{
			id: 6,
			name: "Términos y Condiciones",
			url: "/informacion#terminos-y-condiciones",
		},
		{
			id: 7,
			name:
				country_code === "CO"
					? "Políticas de tratamiento de datos"
					: "Políticas de Privacidad",
			url: "/informacion#politicas-de-privacidad",
		},
	]);

	useEffect(() => {
		if (country_code === "CO") {
			const dataCol = [
				{
					id: 1,
					name: "Nosotros",
					url: "/informacion#anuncia-con-nosotros",
				},
				{
					id: 3,
					name: "Recomendaciones de Seguridad",
					url: "/informacion#recomendaciones-de-seguridad",
				},
				{
					id: 8,
					name: "Políticas de Cookies",
					url: "/informacion#politicas-de-cookies",
				}
			];

			setData((prevData) => [
				...prevData,
				...dataCol
			])
		}
	}, [])

	return {
		data,
	};
};
