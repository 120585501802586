import { FRAGMENT_PROPERTY_FAVORITES } from "../../User/User.querys";
import { UserDispatchContext } from "../../../Contexts/User/context";
import { gql } from "@apollo/client";
import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { useUser } from "../../User/User.hook";

export const FAVORITE_MUTATION = gql`
	mutation favorite($property_id: ID!) {
		toggleFavorite(property_id: $property_id) {
			id
			favorites {
				id
				property_id
				property{ id ${FRAGMENT_PROPERTY_FAVORITES.query()} }
			}
		}
	}
`;

export const useFavorite = ({ id }) => {
	const {
		user: { data, error, loading },
	} = useUser();
	const dispatch = useContext(UserDispatchContext);

	const isFavorite =
		error || loading || !data?.me?.favorites
			? false
			: data.me.favorites.some(e => e?.property_id == id);

	const [toggleFavorite, { data: mutationData, loading: mutationLoading }] = useMutation(
		FAVORITE_MUTATION,
		{
			variables: { property_id: id },
			onError: error => { },
			onCompleted: d => {
				const { favorites } = d.toggleFavorite;
				dispatch({ type: "merge", payload: { favorites } });
			},
		}
	);

	return {
		loading,
		mutationLoading,
		isFavorite,
		error,
		toggleFavorite,
		isFirstFavoriteJustAdded:
			mutationData &&
			!!mutationData.toggleFavorite &&
			mutationData.toggleFavorite.favorites?.length === 1,
	};
};
