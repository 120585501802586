import { useContext } from "react";
import { AppLinks } from "./AppLinks/web";
import { Flags } from "./Flags/web";
import './styles.less'
import { ConfigStateContext } from "../../../Contexts/Configurations/context";
import { firstLetterToUppercase } from "src/utils/Functions";

export const MoreAboutUs = () => {
	const { country_code, translations, isDefault } = useContext(ConfigStateContext);

	return (
		<div className="mau">
			<div className="mau__box">
				<h4 className="title">{firstLetterToUppercase(translations.descarga)} la app </h4>
				<AppLinks />
			</div>
			{country_code !== "CO" && !isDefault && <div className="mau__box">
				<h4 className="title"  > Más InfoCasas </h4>
				<Flags />
			</div>}
		</div>
	)
}
