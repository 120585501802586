import "./styles.less";

import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Col, Popover, Row, Skeleton, Space, Typography } from "antd";

import { PropComponentMode } from "../PropertyInterfaces";
import React, { useContext } from "react";
import { TimeAgo } from "../../Days/TimeAgo/web";
import { usePriceTag } from "./PriceTag.hook";
import { useTheme } from "../../../Styles/ThemeHook";
import { ConfigStateContext } from "../../../Contexts/Configurations/context";
import { CommonExpenses, Currency, OperationType, Price, PriceVaritation } from "shared-components";

const { Text, Paragraph } = Typography;

export interface PriceTagProps {
	price: Price;
	maxPrice?: Price;
	priceVariation?: PriceVaritation;
	commonExpenses?: CommonExpenses;
	temporalPrice?: number;
	operationType?: OperationType;
	temporalCurrency?: Currency;
	showExpenses?: boolean;
	threeDigitsFormat?: boolean;
	showOperationType?: boolean;
	showPriceVariation?: boolean;
	showFromText?: boolean;
	mode?: PropComponentMode;
	shortExpensesText?: boolean;
	skeleton?: string;
	isProject?: boolean;
	loading?: boolean;
	isMapFeatured?: boolean;
	include_administration?: boolean;
}

export function PriceTag({
	showExpenses = true,
	threeDigitsFormat = false,
	showOperationType = false,
	showPriceVariation = true,
	showFromText = true,
	shortExpensesText = true,
	mode = "auto",
	skeleton = "front",
	loading = false,
	price,
	maxPrice = null,
	isProject = false,
	isMapFeatured = false,
	include_administration = false,
	...rest
}: PriceTagProps) {
	const { data } = usePriceTag({
		threeDigitsFormat,
		showFromText,
		shortExpensesText,
		mode,
		price,
		maxPrice,
		isProject,
		...rest,
	});
	const { theme } = useTheme();
	const { country_code } = useContext(ConfigStateContext);

	if (loading) {
		return <PriceTagSkeleton direction={skeleton !== "front" ? "horizontal" : "vertical"} />;
	}

	const PriceVariationIndicator = () => {
		const content = () => {
			return (
				<Paragraph>
					<Text>
						<TimeAgo date={data.price_variation.date} bold capitalize />
						{" " + "el precio del inmueble" + " "}
						<Text strong>
							{data.price_variation.difference > 0 ? "subiÃ³" : "bajÃ³"}
						</Text>
						{" " + "un" + " "}
						<Text strong>{data.price_variation.percentage}%</Text>.
						<br />
						{data.price_variation.amount && (
							<Text>
								{"Precio Anterior"}: {data.price_variation.text}
							</Text>
						)}
					</Text>
				</Paragraph>
			);
		};

		return (
			<Popover placement="bottom" content={content()} title={"Cambio de Precio"}>
				{data.price_variation.difference <= 0 ? (
					<ArrowDownOutlined
						style={{ color: theme.colors.successColor, marginRight: 10 }}
					/>
				) : (
					<ArrowUpOutlined style={{ color: theme.colors.errorColor, marginRight: 10 }} />
				)}
			</Popover>
		);
	};

	const priceDetail = () => {
		/* const adminValue = data.price.admin_included - data.price.amount;
		const showAdminIncluded = adminValue === 0 && include_administration; */
		if(include_administration){
			return (
				<Col className="expenses-col">
					<Text className="commonExpenses body body-regular body-1 medium">
						{"Incluye Administración"}
					</Text>
				</Col>
			);
		}
		else if (showExpenses) {
			return (
				<Col className="expenses-col">
					<Text className="commonExpenses body body-regular body-1 medium">
						{data.commonExpenses.text}
					</Text>
				</Col>
			);
		}
	
		return null;
	};

	return (
		<Row gutter={8} align={"middle"} className="property-price-tag">
			<Col style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
				{showPriceVariation && data.price_variation && <PriceVariationIndicator />}
				<Text className={isMapFeatured ? "heading high" : "price heading heading-3 high"} strong>
					{(!data?.price?.currency || !data?.price || data?.price.amount == 0 || data.price.amount < 5) ? "Consultar" : data.price.text
					}
				</Text>
				{country_code === "ce3" && <span className="qty-price">/noche</span>}
			</Col>

			{(isProject && data.price.maxPriceText !== "") &&
				<Col style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Text className="price heading heading-3 high" strong>
						{data.price.maxPriceText}
					</Text>
				</Col>
			}

			{showOperationType && (
				<Col span={24}>
					<Text className="operation_type body body-regular body-1 medium">
						{"Precio de"} {data.operationType.text}
					</Text>
				</Col>
			)}
			{priceDetail()}
		</Row>
	);
}

export const PriceTagSkeleton = ({ direction }) => {
	return (
		<Space align={"end"} className="price-tag-skeleton" direction={direction}>
			<Skeleton.Button active />
			<Skeleton.Button active />
		</Space>
	);


};