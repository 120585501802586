import "./styles.less";

import React, { useContext } from "react";
import { useFavorite } from "./hook";
import { useUser } from "shared-components/Components/User/User.hook";
import { HeartIcon } from "shared-components/Components/CustomIcons/HeartIcon/web";
import { Button } from "shared-components/Components/Buttons/web";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";

export function Favorite({
	id,
	type = "default",
	isEspecial,
	isDisabledProperty = false,
}: {
	id: any;
	type?: "default" | "justIcon";
	isEspecial?: Boolean;
	isDisabledProperty?: boolean;
}) {
	const handleClickShowNotification = async ({
		type = 'success',
		message = '',
		description = '',
	}) => {
		const { showNotification } = await import("shared-components/Components/CustomNotification/web");
		showNotification(
			type,
			message,
			description
		);
	}
	const { isFavorite, toggleFavorite, mutationLoading, loading } = useFavorite({ id });
	const {
		user: { data },
	} = useUser();
	const { country_code } = useContext(ConfigStateContext);
	const fillColor = country_code === "CO" ?
		isFavorite ? "#077BE7" : "" :
		isFavorite ? "#fc7b27" : ""
	const strokeColor = country_code === "CO" ? 
		isFavorite ? "#077BE7" : "#456787" :
		isFavorite ? "#fc7b27" : "#fff"

	const successMessage = async (e) => {
		const added = e.data.toggleFavorite.favorites.find(e => e.property_id == id);
		if (added) {
			handleClickShowNotification({
				message: "Propiedad agregada a favoritos",
				description: "Te avisaremos automáticamente cuando cambie de precio o se actualice su estado.",
			});
		} else {
			handleClickShowNotification({
				message: "Propiedad eliminada de favoritos",
				description: "No recibirás avisos automáticos de la propiedad.",
			});
		}
	};

	// Valido si muestro el error solo si el usuario esta logeado ya que
	// Solo puede ser favorito cuando hay usuario en sesion.
	const errorMessage = async () => {
		if (data && data.me) {
			handleClickShowNotification({
				message: "Error en favoritos",
				description: "Hubo un error. Vuelva a intentarlo.",
				type: "error",
			});
		}
	};

	return <Button type={type == 'justIcon' ? 'noStyles' : 'secondary'}
		disabled={loading || isDisabledProperty}
		className={`favorite-button ${type}`}
		onClick={e => {
			e.preventDefault()
			e.stopPropagation()
			toggleFavorite()
				.then(successMessage)
				.catch(errorMessage)
		}}
	>
		<HeartIcon width={18} fillOpacity={isFavorite ? 0.8 : 0.4} strokeColor={strokeColor} fillColor={fillColor} />
		{!isEspecial
			? type !== "justIcon"
				? isFavorite
					? <span>Guardado</span>
					: <span>Guardar</span>
				: null
			: null}
	</Button>

}
