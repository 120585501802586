import { FragmentDefiner, useReadFragment } from "../../../../../GlobalHooks/useReadFragment";
import { useContext, useEffect, useState } from "react";

import { ConfigStateContext } from "../../../../../Contexts/Configurations/context";
import { PropComponentMode } from "../../../PropertyInterfaces";
import { gql, useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { ModalState } from "../../TextForm/type";

const REQUEST_WPP_MUTATION = gql`
	mutation request_wpp_mutation($property_id: Int!, $type: PropEntityType) {
		requestPhoneAgent(property_id: $property_id, isWpp: true, type: $type){
			name
			phone
		}
	}
`;

export interface OwnerWspInterface {
	id: number | string;
	mode: PropComponentMode;
	disabled?: boolean;
	queryRouter?: string;
	onWpClick?: () => void;
	forceState?: ModalState;
	owner?: any,
	isProject: boolean
}

export const FRAGMENT_HAS_WHATSAPP = new FragmentDefiner(
	"Property",
	`
    title
    link
    owner {
        id
        name
        has_whatsapp
    }
    operation_type {
    	id
    	name
    }
`
);

const queryWpp = gql`query propery($id: ID){
	property(id: $id) {
    title
    link
    owner {
        id
        name
        has_whatsapp
    }
    operation_type {
    	id
    	name
    }
}
}
`

export const useWhatsppButton = (propId: number | string, isProject) => {
	const { main_domain, site_name } = useContext(ConfigStateContext);
	const [wppMessage, setWppMessage] = useState<string>("");
	const { data } = useQuery(queryWpp, {
		variables: {
			id: propId
		}
	});
	const [mutation, { loading, data: wppMutation }] = useMutation(REQUEST_WPP_MUTATION, {
		onError: error => {
			console.error("User Unauthenticated.");
		},
	});
	const [wppNumber, setWppNumber] = useState(undefined);

	useEffect(() => {
		if (wppMutation?.requestPhone) {
			let number = wppMutation?.requestPhone;
			setWppNumber(number)
		}
	}, [wppMutation]);

	useEffect(() => {
		if (data?.property?.title && data?.property?.link) {
			setWppMessage(
				encodeURIComponent(`Hola, ví esta propiedad en ${site_name} y me gustaría tener más información. ${data?.title} https://${main_domain}/${data?.property.link}`)
			);
		}
	}, [data]);

	let LINK_WPP =
		wppNumber && wppMessage
			? `https://api.whatsapp.com/send?phone=${wppNumber}&text=${wppMessage}&app_absent=1`
			: null;

	//nota: como el telefono se guarda en un state, no en cache de apolo. cada ver teléfono va a hacer la mutation por separado
	const viewWpp = () => {
		return mutation({
			variables: {
				property_id: propId,
				type: isProject ? "PROJECT" : "PROPERTY"
			},
		});
	};

	const filteredPhones = new Set(wppMutation?.requestPhoneAgent?.map(item => item.phone).filter(phone => phone))
	const phones = Array.from(filteredPhones).join(';')
	
	return {
		viewWpp,
		loading,
		wppMessage,
		linkWpp: LINK_WPP,
		owner_name: data?.property?.owner?.name,
		wppPhone: phones,
		prop_title: data && data.property ? data.property.title : "",
		operation_type_name: data && data?.property?.operation_type?.name ? data.property.operation_type.name : "",
		hasWhatsapp: data ? data.property?.owner.has_whatsapp : false,
	};
};

