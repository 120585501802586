import * as React from "react"

export const HighlightBuilderIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={12}
        fill="none"
        {...props}
    >
        <path
            fill="#077BE7"
            d="M9.973 5.055a.762.762 0 0 0-.687-.44H8.099a.23.23 0 0 1-.231-.23V.462a.462.462 0 0 0-.82-.292L2.181 6.134a.759.759 0 0 0 .577 1.25h1.187a.23.23 0 0 1 .23.232v3.923a.461.461 0 0 0 .82.291l4.868-5.964a.762.762 0 0 0 .11-.81Z"
        />
    </svg>
)