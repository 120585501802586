import * as React from "react"

export const ProductTagIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={12}
        fill="none"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                fill="#6ED39F"
                d="m.69 5.69 5.89 5.81a1 1 0 0 0 .695.28A1.001 1.001 0 0 0 8 11.5l3.53-3.535A1 1 0 0 0 11.5 6.5L5.65.75 5.625.72A1.89 1.89 0 0 0 4.43.22H1.18a1 1 0 0 0-1 1V4.5c.025.444.205.866.51 1.19Zm1.74-2.46a.75.75 0 1 1 1.5.04.75.75 0 0 1-1.5-.04Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h12v12H0z" />
            </clipPath>
        </defs>
    </svg>
)