import { useContext, useEffect, useState } from "react";

import { ConfigStateContext } from "../../Contexts/Configurations/context";
import Console from "../../../lib/console";
import { useFacebookPixel } from "./FacebookPixel.hook";
import { useUser } from "../../Components/User/User.hook";
import OneSignal from 'react-onesignal';

export const useOneSignal = () => {
	const { onesignal_configuration, site_name } = useContext(ConfigStateContext);
	const FP = useFacebookPixel();
	const { user } = useUser();
	const IS_BROWSER = typeof window !== "undefined";
	const [isInitialized, setIsInitialized] = useState(false);

	const sendTags = () => {
		const isAllowed = localStorage.getItem("OneSignalAllowed");

		if (isAllowed && isAllowed === "granted") {
			OneSignal.sendTags({
				ic_user_id: user.data.me.id,
				ic_email: user.data.me.email,
				particular: user.data.me.individual
				// nombreContacto: user.data.me.name
				// ic_id_pais: user.data.me.country_id,
			}).then((res) => console.log(res))
		}
	}

	const Init = () => {
		// @ts-ignore
		if (IS_BROWSER && !isInitialized && !window.OneSignal) {
			OneSignal.init({
				appId: onesignal_configuration.app_id,
				allowLocalhostAsSecureOrigin: true,
				notifyButton: { enable: false },
				promptOptions: {
					actionMessage: "Nos gustaría enviarte notificaciones de las últimas novedades!",
					acceptButtonText: "Aceptar",
					cancelButtonText: "No gracias",
				},
				welcomeNotification: {
					title: site_name,
					message: "Gracias por suscribirte!",
				},
			}).then(async () => {
				setIsInitialized(true);

				// @ts-ignore
				OneSignal.showSlidedownPrompt()
					.catch(err => {
						console.log(err)
					});

				console.log("%cInit One Signal", "color:white; background: #e54b4d; padding:2px 4px");
			}).catch(e => {
				Console.warn("No se pudo initializar OneSignal");
				Console.log(e);
			});

			// @ts-ignore
			OneSignal.on("notificationPermissionChange", function (permissionChange) {
				const actionName: string = "WebPushNotifications";
				FP.StandardEvent(actionName);

				if (permissionChange.to === "granted") {
					localStorage.setItem("OneSignalAllowed", "granted");
				} else {
					localStorage.setItem("OneSignalAllowed", "denied");
				}
			})

			// @ts-ignore
			OneSignal.on("permissionPromptDisplay", function (permissionChange) {
				const actionName: string = "WebPushNotifications";
				FP.StandardEvent(actionName);
			})
		}
	};

	return { InitOneSignal: Init, sendTags };
};
