import { FragmentDefiner, useReadFragment } from "../../../../../GlobalHooks/useReadFragment";

import { PropComponentMode } from "../../../PropertyInterfaces";
import { gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@apollo/client";
import { ModalState } from "../../TextForm/type";

const REQUEST_PHONE_MUTATION = gql`
	mutation request_phone_mutation($property_id: Int!, $type: PropEntityType) {
		requestPhoneAgent(property_id: $property_id, isWpp: false, type: $type){
			name
			phone
		}
	}
`;

export interface OwnerPhoneInterface {
	id: number | string;
	type?: "callButton" | "viewPhone";
	mode: PropComponentMode;
	disabled?: boolean;
	property_info?: any;
	seller?: boolean;
	queryRouter?: string;
	icon?: boolean;
	btnType?: "text" | "default";
	onPhoneClick?: () => void;
	forceState?: ModalState;
	owner?: any;
	isProject?: boolean
}

export const FRAGMENT_MASKED_PHONE = new FragmentDefiner(
	"Property",
	`
	title
	seller {
		id
		name
		masked_phone
	}
    owner {
        id
        name
        masked_phone
    }
    operation_type {
    	id
    	name
    }
`
);

const queryMaskedPhone = gql`query propery($id: ID){
	property(id: $id) {
		title
		seller {
			id
			name
			masked_phone
		}
		owner {
			id
			name
			masked_phone
		}
		operation_type {
			id
			name
		}
}
}
`

export const usePhoneButton = ({ property_id, isProject, seller = false }) => {
	const [mutation, { loading, data: mutationData, called }] = useMutation(
		REQUEST_PHONE_MUTATION,
		{
			onError: error => { },
		}
	);

	const { data } = useQuery(queryMaskedPhone, { variables: { id: property_id } });

	//nota: como el telefono se guarda en un state, no en cache de apolo. cada ver teléfono va a hacer la mutation por separado
	const viewPhone = async () => {
		return await mutation({
			variables: {
				property_id,
				type: isProject ? "PROJECT" : "PROPERTY"
			},
		});
	};

	let masked_phone = seller ? data?.property?.seller?.masked_phone : data?.property?.owner?.masked_phone;
	masked_phone = masked_phone || data?.property?.owner?.masked_phone;

	const filteredPhones = new Set(mutationData?.requestPhoneAgent?.map(item => item.phone).filter(phone => phone))
	const phones = Array.from(filteredPhones).join(';')

	return {
		viewPhone,
		owner_name: data?.property?.owner.name,
		masked_phone: masked_phone,
		prop_title: data && data.property ? data.property.title : "",
		operation_type_name: data && data?.property?.operation_type?.name ? data.property.operation_type.name : "",
		phone: phones,
		loading,
		called,
	};
};




