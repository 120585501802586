const monthsSpanish = [
  "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

export const formatDateRange = (startDate, endDate) => {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  const startDay = startDateObj.getUTCDate();
  const endDay = endDateObj.getUTCDate();

  const startMonth = startDateObj.toLocaleString('es-ES', { month: 'short', timeZone: 'UTC' });
  const endMonth = endDateObj.toLocaleString('es-ES', { month: 'short', timeZone: 'UTC' });

  const days = [];
  const months = [];

  days.push(startDay.toString());
  if (startDay !== endDay) {
    days.push(endDay.toString());
  }

  if (startMonth !== endMonth) {
    months.push(startMonth);
    months.push(endMonth);
  } else {
    months.push(startMonth);
  }

  return {
    days: days,
    months: months,
  };
}

export const formatHoursAndDates = (startHour, endHour, startDate, endDate) => {
    const hasStartHour = startHour !== "";
    const hasEndHour = endHour !== "";

    if (!hasStartHour && !hasEndHour) {
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

        const startDayName = daysOfWeek[startDateObj.getDay()];
        const endDayName = daysOfWeek[endDateObj.getDay()];

        return `${startDayName} a ${endDayName}`;
    }

    const [startHourStr, startMinuteStr] = hasStartHour ? startHour.split(':') : ["", ""];
    const [endHourStr, endMinuteStr] = hasEndHour ? endHour.split(':') : ["", ""];

    const startHourNum = parseInt(startHourStr, 10);
    const startMinuteNum = parseInt(startMinuteStr, 10);
    const endHourNum = parseInt(endHourStr, 10);
    const endMinuteNum = parseInt(endMinuteStr, 10);

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const isSameDay = startDateObj.toDateString() === endDateObj.toDateString();

    const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

    const startDayName = daysOfWeek[startDateObj.getDay()];
    const endDayName = daysOfWeek[endDateObj.getDay()];

    const startHourFormatted = `${startHourNum}:${startMinuteNum < 10 ? '0' : ''}${startMinuteNum}`;
    const endHourFormatted = `${endHourNum}:${endMinuteNum < 10 ? '0' : ''}${endMinuteNum}`;

    if (isSameDay) {
        return `${startDayName} desde ${startHourFormatted} hasta ${endHourFormatted}`;
    } else {
        return `${startDayName} a ${endDayName} desde ${startHourFormatted} hasta ${endHourFormatted}`;
    }
}

export const formatPastEventsDate = (startDate, endDate) => {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  const startDay = startDateObj.getUTCDate();
  const startMonth = monthsSpanish[startDateObj.getUTCMonth()];
  const startYear = startDateObj.getFullYear();

  const endDay = endDateObj.getUTCDate();
  const endMonth = monthsSpanish[endDateObj.getUTCMonth()];

  if (startDate === endDate) {
    return `${startDay} de ${startMonth} del ${startYear}`;
  } else if (startMonth === endMonth) {
    return `${startDay} hasta ${endDay} de ${startMonth} del ${startYear}`;
  } else {
    let startNextMonth = startDateObj.getUTCMonth() + 1; // Obtener el siguiente mes
    let startNextYear = startDateObj.getFullYear();

    if (startNextMonth === 12) {
      startNextMonth = 0; // Reiniciar a enero si el siguiente mes es diciembre
      startNextYear += 1;
    }

    const startNextDate = new Date(startNextYear, startNextMonth, 1); // El primer día del siguiente mes

    return `${startDay} de ${startMonth} hasta el ${endDay} de ${endMonth} del ${startYear}`;
  }
}

export function formatBannerEventDate(startDate) {
  const startDateObj = new Date(startDate);

  const startDay = startDateObj.getUTCDate();
  const startMonth = startDateObj.getUTCMonth() + 1;
  const startYear = startDateObj.getFullYear() % 100;

  const formattedStartDate = `${startDay}/${startMonth}/${startYear}`;

  return formattedStartDate;
}

export function formatTagEventDate(startDate) {
  const startDateObj = new Date(startDate);

  const startDay = startDateObj.getUTCDate();
  const startMonth = startDateObj.getUTCMonth() + 1;

  const formattedStartDate = `${startDay}/${startMonth}`;

  return formattedStartDate;
}

export const addLinkEventUTMSource = (link) => {
  if (!/utm_/.test(link)) {
    if (link.includes('?')) {
      link += '&utm_source=banner-eventos-detalle';
    } else {
      link += '?utm_source=banner-eventos-detalle';
    }
  }

  return link;
}