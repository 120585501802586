import { FetchError } from "src/definitions/common";

async function fetcher<T>(info: RequestInfo, config?: RequestInit): Promise<T> {
	//TODO: implement token validation
	const options = {
		...config,
		headers: {
			...config?.headers,
		},
	};
	return await originalRequest(info, options);
}

async function originalRequest<T>(info: RequestInfo, config?: RequestInit): Promise<T> {
	try {
		const options = {
			...config,
			headers: {
				"Content-Type": `application/json`,
				...config?.headers,
			},
		};

		const response = await fetch(info, options);
		if (response.status === 200 || response.status === 201) {
			try {
				return (await response.json()) as T;
			} catch (err) {
				return {} as T;
			}
		}

		throw response;
	} catch (e) {
		const error = e as FetchError;

		if (!error.detail) {
			error.detail = error.message;
		}
		throw error;
	}
}

export default fetcher;
