import { ImageIcon } from "shared-components/Components/CustomIcons/ImageIcon/web";
import "./styles.less";


export const PropertyCardSkeleton = () => {
	return (
		<div className={"property-card-skeleton"} >
			<div className="cardImgSkelleton">
				<ImageIcon />
			</div>
			<div className={"property-card-body"}>
				<span style={{ width: '38%' }}></span>
				<span></span>
				<span></span>
				<span style={{ width: '61%' }}></span>
			</div>

		</div>

	);
}