import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { useContact } from "../../InformationRequest/hook";
import { PropComponentMode } from "../../PropertyInterfaces";
import getConfig from "next/config";
import { Grid } from "antd";
import { LoadingIcon } from "shared-components/Components/CustomIcons/LoadingIcon/LoadingIcon";
import { Modal } from "shared-components/Components/Modal/web";
import SuccessIcon from "shared-components/Components/CustomIcons/SuccessIcon/web";

const { NODE_ENV } = getConfig().publicRuntimeConfig;
const { useBreakpoint } = Grid;

export const CardCtaLead = ({ id, mode, isInListing, show }: { id: number, mode: PropComponentMode, isInListing: boolean, show: boolean }) => {
    const Router = useRouter();
    const screen = useBreakpoint();
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState();

    const {
        doContactMutation,
        isSendedConsultation
    } = useContact({
        id,
        onComplete: () => { },
        type: "lead",
        mode,
        source: isInListing ? 22 : screen.lg ? 0 : 4,
        utm: Router.query,
        env: NODE_ENV == "development" || NODE_ENV == "beta" ? "development" : "production",
    });

    const setTypeCTA = (type: number) => {
        setIsLoading(true)
        doContactMutation(type);
    };

    const handleShowAlert = (isVisible) => {
        setShowAlert(isVisible)
    }

    useEffect(() => {
        if (isSendedConsultation.askMoreInfo) {
            setIsLoading(false)
            handleShowAlert(true)
        }
    }, [isSendedConsultation.askMoreInfo])

    return (
        <>
            <div className={`overlay-cta ${show ? "show" : "hide"}`}>
                <span>¿Te interesa?</span>

                <button
                    className="btn-send-lead"
                    onClick={() => setTypeCTA(4)}
                    disabled={isSendedConsultation.askMoreInfo}
                >
                    Pide más fotos al anunciante
                    {isLoading && <LoadingIcon classes="loading-icon" />}
                </button>
            </div>

            <Modal
                show={showAlert}
                width="400px"
                title=""
                onClose={() => handleShowAlert(false)}
            >
                <span className="text-success"><SuccessIcon width={40} /></span>

                <h3>¡Consulta enviada con éxito!</h3>
            </Modal>
        </>
    )
}