import { firstLetterToUppercase } from "src/utils/Functions";
import { AmenitiesDataOptionsTypes, DataOptionsTypes } from "./types";

export const operation_type_id: DataOptionsTypes[] = [
	{
		text: "Venta",
		value: 1,
		countries: ["UY", "PY", "BO", "PE", "CO"],
	},
	{
		text: "Alquiler",
		value: 2,
		countries: ["UY", "PY", "BO", "PE"],
	},
	{
		text: "Alquiler Temporal",
		value: 4,
		countries: ["UY", "PY", "BO", "PE"],
	},
	{
		text: "Arriendo",
		value: 2,
		countries: ["CO"],
	},
	{
		text: "Vacacional",
		value: 4,
		countries: ["CO"],
	},
	{
		text: "Anticrético",
		value: 5,
		countries: ["BO"],
	},
];

export const property_type_id: DataOptionsTypes[] = [
	{
		text: "Todos",
		value: 0,
		countries: ["CO"],
	},
	{
		text: "Casa",
		value: 1,
		countries: ["UY", "PY", "BO", "PE", "CE3", "CO"],
	},
	{
		text: "Apartamento",
		value: 2,
		countries: ["CE3", "UY", "CO"],
	},
	{
		text: "Apartaestudio",
		value: 14,
		countries: ["CO"],
	},
	{
		text: "Cabaña",
		value: 15,
		countries: ["CO"],
	},
	{
		text: "Casa Campestre",
		value: 16,
		countries: ["CO"],
	},
	{
		text: "Casa Lote",
		value: 17,
		countries: ["CO"],
	},
	{
		text: "Departamento",
		value: 2,
		countries: ["PY", "BO", "PE"],
	},
	{
		text: "Terreno",
		value: 3,
		countries: ["UY", "PY", "PE"],
	},
	{
		text: "Lote o Terreno",
		value: 3,
		countries: ["BO"],
	},
	{
		text: "Finca",
		value: 6,
		countries: ["CO"],
	},
	{
		text: "Habitación",
		value: 18,
		countries: ["BO", "CO"],
	},
	{
		text: "Lote",
		value: 3,
		countries: ["CO"],
	},
	{
		text: "Local Comercial",
		value: 4,
		countries: ["UY", "PY", "BO", "PE"],
	},
	{
		text: "Bodega",
		value: 19,
		countries: ["CO"],
	},
	{
		text: "Consultorio",
		value: 9,
		countries: ["CO"],
	},
	{
		text: "Local",
		value: 4,
		countries: ["CO"],
	},
	{
		text: "Oficina",
		value: 5,
		countries: ["UY", "PY", "BO", "PE", "CO"],
	},
	{
		text: "Chacra o Campo",
		value: 6,
		countries: ["UY", "PY", "PE"],
	},
	{
		text: "Chacra",
		value: 6,
		countries: ["CE3"],
	},
	{
		text: "Quinta o Campo",
		value: 6,
		countries: ["BO"],
	},
	{
		text: "Garaje o Cochera",
		value: 8,
		countries: ["UY", "PY", "BO", "PE"],
	},
	{
		text: "Parqueadero",
		value: 8,
		countries: ["CO"],
	},
	{
		text: "Negocio Especial",
		value: 9,
		countries: ["UY", "PY", "BO", "PE"],
	},
	{
		text: "Edificio",
		value: 10,
		countries: ["UY", "PE", "CO"],
	},
	{
		text: "Edificio u Hotel",
		value: 10,
		countries: ["PY"],
	},
	{
		text: "Hotel",
		value: 11,
		countries: ["UY", "PE"],
	},
	{
		text: "Local industrial o galpón",
		value: 12,
		countries: ["UY", "BO", "PE"],
	},
	{
		text: "Tinglado o Depósito",
		value: 12,
		countries: ["PY"],
	},
	{
		text: "Otro",
		value: 13,
		countries: ["UY", "PY", "BO", "PE"],
	},
	{
		text: "Dúplex",
		value: 14,
		countries: ["PY"],
	},
	{
		text: "Condominio",
		value: 14,
		countries: ["BO"],
	},
];

export const getBedrooms = (translations): DataOptionsTypes[] => {
	return [
		{
			text: "Monoambiente",
			value: 0,
			countries: ["UY", "PY", "BO", "PE", "CE3"],
		},
		{
			text: `1 ${translations.habitaciones}`,
			value: 1,
			countries: ["UY", "PY", "BO", "PE", "CE3", "CO"],
		},
		{
			text: `2 ${translations.habitaciones}`,
			value: 2,
			countries: ["UY", "PY", "BO", "PE", "CE3", "CO"],
		},
		{
			text: `3 ${translations.habitaciones}`,
			value: 3,
			countries: ["UY", "PY", "BO", "PE", "CE3", "CO"],
		},
		{
			text: `4 ${translations.habitaciones}`,
			value: 4,
			countries: ["UY", "PY", "BO", "PE", "CE3", "CO"],
		},
		{
			text: `5+ ${translations.habitaciones}`,
			value: 5,
			countries: ["UY", "PY", "BO", "PE", "CE3", "CO"],
		},
	];
};

export const currencyType: DataOptionsTypes[] = [
	{
		text: "$ - Pesos Uruguayos",
		countries: ["UY"],
		value: 2,
	},
	{
		text: "Gs. - Guaraníes",
		countries: ["PY"],
		value: 3,
	},
	{
		text: "S/ - Soles",
		countries: ["PE"],
		value: 6,
	},
	{
		text: "U$S - Dólares Americanos",
		countries: ["UY", "PY", "BO", "PE", "CE3"],
		value: 1,
	},
	{
		text: "$ - Pesos Colombianos",
		countries: ["CO"],
		value: 4,
	},
];

export const bathrooms: DataOptionsTypes[] = [
	{
		text: "1 baño",
		value: 1,
	},
	{
		text: "2 baños",
		value: 2,
	},
	{
		text: "3+ baños",
		value: 3,
	},
];

export const garages: DataOptionsTypes[] = [
	{
		text: "1 Parqueadero",
		value: 1,
	},
	{
		text: "2 Parqueaderos",
		value: 2,
	},
	{
		text: "3 Parqueaderos",
		value: 3,
	},
	{
		text: "4+ Parqueaderos",
		value: 4,
	},
];

export const getSurfaceRange = (translations): DataOptionsTypes[] => {
	return [
		{
			text: `${firstLetterToUppercase(translations.construidos)}`,
			value: "edificados",
		},
		{
			text: "Totales",
			value: "totales",
		},
	];
};

export const amenities: AmenitiesDataOptionsTypes[] = [
	{
		value: 1,
		text: "Balcón",
		grupo: "Espacios",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/YardIcon/web"),
	},
	{
		value: 2,
		text: "Box",
		grupo: "Principales",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/BoxIcon/web"),
	},
	{
		value: 3,
		text: "Calefacción Individual",
		grupo: "Principales",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/HeatingIcon/web"),
	},
	{
		value: 4,
		text: "Calefón",
		grupo: "Principales",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/CalefonIcon/web"),
	},
	{
		value: 5,
		text: "Cochera",
		grupo: "Espacios",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/GarageIcon/web"),
	},
	{
		value: 6,
		text: "Depósito",
		grupo: "Principales",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/DepositIcon/web"),
	},
	{
		value: 7,
		text: "Dormitorio de servicio",
		grupo: "Espacios",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () =>
			await import("shared-components/Components/CustomIcons/ServiceRoomIcon/web"),
	},
	{
		value: 8,
		text: "Estufa a Leña",
		grupo: "Comodidades",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () =>
			await import("shared-components/Components/CustomIcons/WoodStoveIcon/web"),
	},
	{
		value: 10,
		text: "Jacuzzi",
		grupo: "Espacios",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/JacuzziIcon/web"),
	},
	{
		value: 11,
		text: "Línea Blanca",
		grupo: "Comodidades",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () =>
			await import("shared-components/Components/CustomIcons/WhiteLineIcon/web"),
	},
	{
		value: 12,
		text: "Losa Radiante",
		grupo: "Comodidades",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () =>
			await import("shared-components/Components/CustomIcons/LosaRadianteIcon/web"),
	},
	{
		value: 13,
		text: "Parrillero",
		grupo: "Espacios",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/GrillIcon/web"),
	},
	{
		value: 14,
		text: "Placard en cocina",
		grupo: "Comodidades",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () =>
			await import("shared-components/Components/CustomIcons/PlacardKitchenIcon/web"),
	},
	{
		value: 15,
		text: "Placard en dormitorio",
		grupo: "Comodidades",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () =>
			await import("shared-components/Components/CustomIcons/PlacardBedroomIcon/web"),
	},
	{
		value: 16,
		text: "Terraza",
		grupo: "Espacios",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/TerraceIcon/web"),
	},
	{
		value: 17,
		text: "Terraza Lavadero",
		grupo: "Espacios",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () =>
			await import("shared-components/Components/CustomIcons/TerraceLaundryIcon/web"),
	},
	{
		value: 18,
		text: "Vestidor",
		grupo: "Principales",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () =>
			await import("shared-components/Components/CustomIcons/DressingRoomIcon/web"),
	},
	{
		value: 20,
		text: "Ascensor",
		grupo: "Principales",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/ElevatorIcon/web"),
	},
	{
		value: 21,
		text: "Barbacoa",
		grupo: "Principales",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/BarbacueIcon/web"),
	},
	{
		value: 22,
		text: "Bungalow",
		grupo: "Espacios",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/BungalowIcon/web"),
	},
	{
		value: 23,
		text: "GYM",
		grupo: "Espacios",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/GymIcon/web"),
	},
	{
		value: 25,
		text: "Lavandería",
		grupo: "Comodidad del edificio",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/LaundryIcon/web"),
	},
	{
		value: 27,
		text: "Piscina",
		grupo: "Principales",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/PoolIcon/web"),
	},
	{
		value: 28,
		text: "Playroom",
		grupo: "Comodidad del edificio",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/PlayroomIcon/web"),
	},
	{
		value: 29,
		text: "Salón de uso común",
		grupo: "Comodidad del edificio",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () =>
			await import("shared-components/Components/CustomIcons/CommonUseRoomIcon/web"),
	},
	{
		value: 30,
		text: "Spa",
		grupo: "Principales",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/SpaIcon/web"),
	},
	{
		value: 31,
		text: "WiFi",
		grupo: "Principales",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/WifiIcon/web"),
	},
	{
		value: 32,
		text: "Agua Caliente Central",
		grupo: "Principales",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/HotWaterIcon/web"),
	},
	{
		value: 33,
		text: "Calefacción Central",
		grupo: "Espacios",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/HeatingIcon/web"),
	},
	{
		value: 34,
		text: "Instalación de TV cable",
		grupo: "Principales",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/TVIcon/web"),
	},
	{
		value: 35,
		text: "Previsión A.A.",
		grupo: "Comodidades",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/ACICon/web"),
	},
	{
		value: 36,
		text: "Aire Acondicionado",
		grupo: "Principales",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/ACICon/web"),
	},
	{
		value: 37,
		text: "Gas por Cañería",
		grupo: "Comodidades",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/PipedGasIcon/web"),
	},
	{
		value: 40,
		text: "Altillo",
		grupo: "Espacios",
		IDtipos: 1,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/AltilloIcon/web"),
	},
	{
		value: 45,
		text: "Calefacción",
		grupo: "Comodidades",
		IDtipos: 1,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/HeatingIcon/web"),
	},
	{
		value: 65,
		text: "Sótano",
		grupo: "Espacios",
		IDtipos: 1,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/BasementIcon/web"),
	},
	{
		value: 69,
		text: "Amueblada",
		grupo: "Principales",
		IDtipos: 1,
		IDoperaciones: null,
		icon: async () =>
			await import("shared-components/Components/CustomIcons/FurnitureIcon/web"),
	},
	{
		value: 70,
		text: "Jardín",
		grupo: "Espacios",
		IDtipos: 1,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/GardenIcon/web"),
	},
	{
		value: 72,
		text: "Patio",
		grupo: "Espacios",
		IDtipos: 1,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/YardIcon/web"),
	},
	{
		value: 74,
		text: "Lavadero",
		grupo: "Comodidades",
		IDtipos: 1,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/LaundryIcon/web"),
	},
	{
		value: 76,
		text: "Sauna",
		grupo: "Principales",
		IDtipos: 1,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/SaunaIcon/web"),
	},
	{
		value: 78,
		text: "Solárium",
		grupo: "Espacios",
		IDtipos: 2,
		IDoperaciones: null,
		icon: async () => await import("shared-components/Components/CustomIcons/SolariumIcon/web"),
	},
	{
		value: 216,
		text: "Walk-in Closet",
		grupo: "Principales",
		IDtipos: 1,
		IDoperaciones: null,
		icon: async () =>
			await import("shared-components/Components/CustomIcons/WalkinClosetIcon/web"),
	},
	{
		value: 218,
		text: "Living comedor",
		grupo: "Espacios",
		IDtipos: 1,
		IDoperaciones: null,
		icon: async () =>
			await import("shared-components/Components/CustomIcons/LivingDinningIcon/web"),
	},
	{
		value: 220,
		text: "Piso porcelanato",
		grupo: "Principales",
		IDtipos: 1,
		IDoperaciones: null,
		icon: async () =>
			await import("shared-components/Components/CustomIcons/PorcelainFloorIcon/web"),
	},
	{
		value: 222,
		text: "Se aceptan mascotas",
		grupo: "Extras",
		IDtipos: 1,
		IDoperaciones: 4,
		icon: async () =>
			await import("shared-components/Components/CustomIcons/PetFriendlyIcon/web"),
	},
	{
		value: 225,
		text: "Se aceptan grupos de jóvenes",
		grupo: "Extras",
		IDtipos: 1,
		IDoperaciones: 4,
		icon: async () => await import("shared-components/Components/CustomIcons/PeopleIcon/web"),
	},
];

export const getPropertyState = (translations): DataOptionsTypes[] => {
	return [
		{
			text: "Todos",
			value: -1,
			countries: ["CO"],
		},
		{
			text: `${firstLetterToUppercase(translations.planos)}`,
			value: 9,
			countries: ["UY", "PY", "BO", "PE", "CE3"],
		},
		{
			text: "En Construcción",
			value: 8,
			countries: ["UY", "PY", "BO", "PE", "CE3"],
		},
		{
			text: `${firstLetterToUppercase(translations.nuevo)}`,
			value: 1,
			countries: ["UY", "PY", "BO", "PE", "CE3", "CO"],
		},
		{
			text: "Usados",
			value: 0,
			countries: ["UY", "PY", "BO", "PE", "CE3", "CO"],
		}
	];
};

export const publicationDate: DataOptionsTypes[] = [
	{
		text: "Indiferente",
		value: -1,
	},
	{
		text: "Hoy",
		value: 0,
	},
	{
		text: "Desde ayer",
		value: 1,
	},
	{
		text: "Última Semana",
		value: 7,
	},
	{
		text: "Últimos 15 días",
		value: 15,
	},
	{
		text: "Últimos 30 días",
		value: 30,
	},
	{
		text: "Últimos 40 días",
		value: 40,
	},
];

export const seaDistance: DataOptionsTypes[] = [
	{
		text: "Frente al mar",
		value: 1,
	},
	{
		text: "Menos de 100 m",
		value: 2,
	},
	{
		text: "200 m",
		value: 3,
	},
	{
		text: "300 m",
		value: 4,
	},
	{
		text: "400 m",
		value: 5,
	},
	{
		text: "500 m",
		value: 6,
	},
	{
		text: "Menos de 1000 m",
		value: 7,
	},
	{
		text: "Más de 1000 m",
		value: 8,
	},
];

export const buildingFloor: DataOptionsTypes[] = [
	{
		text: "Planta Baja",
		value: 0,
	},
	{
		text: "1ro al 5to piso",
		value: 1,
	},
	{
		text: "6to al 10mo piso",
		value: 2,
	},
	{
		text: "+10mo piso",
		value: 3,
	},
	{
		text: "Penthouse",
		value: 4,
	},
];

export const dispositionFilter: DataOptionsTypes[] = [
	{
		text: "No aplica",
		value: 1,
	},
	{
		text: "Al frente",
		value: 2,
	},
	{
		text: "Contrafrente",
		value: 3,
	},
	{
		text: "Interior",
		value: 4,
	},
	{
		text: "Lateral",
		value: 5,
	},
];

export const seasonsFilters: DataOptionsTypes[] = [
	{
		text: "Primera Quincena Diciembre",
		value: "pqdiciembre",
	},
	{
		text: "Segunda Quincena Diciembre",
		value: "sqdiciembre",
	},
	{
		text: "Diciembre",
		value: "diciembre",
	},
	{
		text: "Primera Quincena Enero",
		value: "pqenero",
	},
	{
		text: "Segunda Quincena Enero",
		value: "sqenero",
	},
	{
		text: "Enero",
		value: "enero",
	},
	{
		text: "Primera Quincena Febrero",
		value: "pqfebrero",
	},
	{
		text: "Segunda Quincena Febrero",
		value: "sqfebrero",
	},
	{
		text: "Febrero",
		value: "febrero",
	},
	{
		text: "Réveillon",
		value: "reveillon",
	},
	{
		text: "Semana Santa",
		value: "semanasanta",
	},
	{
		text: "Carnaval",
		value: "carnaval",
	},
];

export const stratum: DataOptionsTypes[] = [
	{
		text: "Estrato 1",
		value: 1,
	},
	{
		text: "Estrato 2",
		value: 2,
	},
	{
		text: "Estrato 3",
		value: 3,
	},
	{
		text: "Estrato 4",
		value: 4,
	},
	{
		text: "Estrato 5",
		value: 5,
	},
	{
		text: "Estrato 6",
		value: 6,
	},
	{
		text: "Campestre",
		value: 100,
	},
];

export const ageOfTheProperty: DataOptionsTypes[] = [
	{
		text: "Todos",
		value: -1,
	},
	{
		text: "Menor a 1 año",
		value: 1,
	},
	{
		text: "De 1 a 8 Años",
		value: 2,
	},
	{
		text: "De 9 a 15 Años",
		value: 3,
	},
	{
		text: "De 16 a 30 Años",
		value: 4,
	},
	{
		text: "Más de 30 años",
		value: 5,
	},
];

