import { FragmentDefiner, useReadFragment } from "../../../GlobalHooks/useReadFragment";
import { useEffect, useState } from "react";

import { FRAGMENT_DESCRIPTION } from "../Description/Description.hook";
import { FRAGMENT_HAS_WHATSAPP } from "../InformationRequest/LeadButtons/WhatsappButton/hook";
import { FRAGMENT_IMAGE } from "../LazyImageGallery/LazyImageGallery.hook";
import { FRAGMENT_LISTINGTYPETAG } from "../ListingTypeTag/ListingType.hook";
import { FRAGMENT_LOCATIONTAG } from "../LocationTag/LocationTag.hook";
import { FRAGMENT_MASKED_PHONE } from "../InformationRequest/LeadButtons/PhoneButton/hook";
import { FRAGMENT_OWNER_LOGO } from "../OwnerLogo/hook";
import { FRAGMENT_PRICETAG } from "../PriceTag/PriceTag.hook";
import { FRAGMENT_PUBLICATIONTIME } from "../PublicationTime/PublicationTime.hook";
import { FRAGMENT_SOCIAL_SHARE } from "../SocialShare/hook";
import { FRAGMENT_TITLE } from "../Title/Title.hook";
import { FRAGMENT_CARD_IMAGE } from "./CardImageGallery/hook";

export const FRAGMENT_PROPERTY_CARD = new FragmentDefiner(
	"Property",
	`
    id
    title
	link
	isExternal
	grouped_ids
	pointType
    property_type {
        id
        name
	}
	project {
		id
		id_form
		link
		isEspecial
		hasCustomLanding
		rooms
		bedrooms
		bathrooms
		minM2
		video
	}
	facilities{
		id
        name
		group
    }
	bedrooms
	rooms
	bathrooms
	guests
	m2
	seaDistanceName
`
).uses(
	FRAGMENT_PRICETAG,
	FRAGMENT_LOCATIONTAG,
	FRAGMENT_LISTINGTYPETAG,
	FRAGMENT_IMAGE,
	FRAGMENT_OWNER_LOGO,
	FRAGMENT_PUBLICATIONTIME,
	FRAGMENT_TITLE,
	FRAGMENT_DESCRIPTION,
	FRAGMENT_SOCIAL_SHARE,
	FRAGMENT_HAS_WHATSAPP,
	FRAGMENT_MASKED_PHONE,
	FRAGMENT_CARD_IMAGE
);

export function usePropertyCard({ property }) {
	if (property?.project.length > 0) {
		const {
			id,
			description,
			title,
			link,
			rooms,
			bedrooms,
			bathrooms,
			minM2,
			video,
			isEspecial,
			hasCustomLanding,
			minPrice,
		} = property.project[0];
		const pathname = "/projectSingle";
		const avoidRouter = property.project[0].isEspecial ?? false;

		return {
			...property,
			id, // Se coloca id de proyecto
			title,
			description,
			link,
			pathname,
			avoidRouter,
			isProject: true,
			hasCustomLanding,
			isEspecial,
			youtube: video,
			price: minPrice,
		};
	} else if (property.grouped_ids) {
		return {
			...property,
			avoidRouter: true,
			isProject: false,
			hasCustomLanding: false,
			isEspecial: false,
		};
	}

	return {
		...property,
		pathname: "/propSingle",
		isProject: false,
		hasCustomLanding: false,
		isEspecial: false,
	};
}

