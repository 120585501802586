import {FragmentDefiner, useReadFragment} from "../../../GlobalHooks/useReadFragment";


export const FRAGMENT_OWNER_LOGO = new FragmentDefiner("Property",`
    owner {
        id
        logo
        name
        inmoLink
        inmoPropsLink
        inmofull
    }
`);

export const useOwnerLogo = ({id}) => {
    const {loading, data, error} = useReadFragment(FRAGMENT_OWNER_LOGO, id);

    return {
        loading,
        error,
        owner: data?.owner,
    }
}