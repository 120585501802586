import { FragmentDefiner, useReadFragment } from "../../../GlobalHooks/useReadFragment";
import { useContext, useEffect, useState } from "react";

import { ConfigStateContext } from "../../../Contexts/Configurations/context";
import { gql } from "@apollo/client/core";
import { useMutation } from "@apollo/client";

export const FRAGMENT_SOCIAL_SHARE = new FragmentDefiner(
	"Property",
	`
	link
    project {
		id
		link
    }
`
);

const SHARE_BY_EMAIL = gql`
	mutation shareProperty($input: ShareByEmailInput!) {
		shareByEmail(input: $input)
	}
`;

export const useSocialShare = ({ id, mode }) => {
	const { loading, data, error } = useReadFragment(FRAGMENT_SOCIAL_SHARE, id);
	const { main_domain } = useContext(ConfigStateContext);
	const [link, setLink] = useState("");
	const [shareProperty, { loading: shareLoading, data: shareData }] = useMutation(SHARE_BY_EMAIL);
	const typeMode = mode == "project" ? "PROJECT" : "PROPERTY";

	useEffect(() => {
		if ((data?.project[0] && mode == "auto") || mode == "project") {
			if (data?.project[0].link) setLink(`https://${main_domain}/${data.project[0].link}`);
		} else {
			if (data?.link) setLink(`https://${main_domain}/${data.link}`);
		}
	}, [data]);

	const share = values => {
		return shareProperty({
			variables: {
				input: {
					property_id: mode == "project" ? data?.project[0].id : id,
					to_email: values.other,
					from_email: values.me,
					type: typeMode,
				},
			},
		});
	};

	return {
		loading,
		error,
		socialMediaLink: link,
		shareByEmail: {
			share,
			loading: shareLoading,
			data: shareData,
		},
	};
};
