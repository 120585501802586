import "./styles.less";

import { useContext } from "react";
import { useRouter } from 'next/router'
import { LinkBox } from "../../Components/SEO/LinkBox/web";
import { MoreAboutUs } from "./MoreAboutUs/web";
import { AppLinksCeee } from "./MoreAboutUs/AppLinksCeee/web";
import { ConfigStateContext } from "../../Contexts/Configurations/context";

const Footer = ({ size = "default", isHome= false }: { size?: "large" | "default", isHome?: boolean }) => {
	const { country_code } = useContext(ConfigStateContext);
	const router = useRouter();

	return (
		<footer className="footer">
			{country_code !== "ce3" && (!isHome || country_code !=="CO") && <MoreAboutUs />}
			{country_code === "ce3" && router.pathname !== "/home" && <AppLinksCeee />}
			<hr color="#C1C5D2" />
			<LinkBox />
			{/* <SocialNetworks /> */}
		</footer>
	);
};

export { Footer };

