import { useContext, useState } from "react";

import { ConfigStateContext } from "../../Contexts/Configurations/context";
import { postRequest } from "../../Utils/Functions";
import { UtmArgs } from "../../Components/Property/InformationRequest/hook";
interface useFormularioType {
	IDform: number;
	env?: "development" | "production";
}

export interface valueFormDataType {
	IDuser?: number;
	nombre: string;
	email: string;
	tel: string | number;
	apellido?: string;
	extra?: string;
	fecha?: string;
	ci?: string;
	source?: number;
}

interface sendDataType extends valueFormDataType, useFormularioType, UtmArgs {
	IDpais: number;
}

export const useFormulario = ({ IDform, env = "production" }: useFormularioType) => {
	const { country_id, main_domain, country_code } = useContext(ConfigStateContext);

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);

	const sendFormulario = async (values: valueFormDataType, utm?: UtmArgs) => {
		setLoading(true);
		const endpointDomain =
			env == "development"
				? `beta.infocasas.com.${country_code.toLocaleLowerCase()}`
				: main_domain;
		const ENDPOINT = `https://${endpointDomain}/?mid=formulario&func=ajax_save&json=true`;

		const utms = utm ? utm : {};
		const sendData: sendDataType = { IDform, IDpais: country_id, ...values, ...utms };
		const res = await postRequest(ENDPOINT, sendData);

		let d,
			err = null;
		if (res) {
			switch (res.error) {
				case "00":
					d = { status: "success" };
					break;
				case "02":
					err = "Su consulta ya ha sido enviada antes" + ".";
					break;
				default:
					err = "Hubo un error. Vuelva a intentarlo.";
					break;
			}
		} else {
			err = { status: "error", message: "Hubo un error. Vuelva a intentarlo." };
			new Error("Error Sending Form to IC1");
		}

		setData(d);
		setError(err);
		setLoading(false);

		return { d, err };
	};

	return { sendFormulario, loading, data, error };
};

