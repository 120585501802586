import { gql, useLazyQuery } from "@apollo/client";
import { useFilters } from "../../../Components/Filters/Filters.hook";
import { useQuery } from "@apollo/client";
import { QUERY_AVAILABLE_FILTERS } from "shared-components/GQL";
import { useContext, useEffect, useState } from "react";
import { Filter } from "shared-components/Contexts/Filters/model";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { currencyType } from "shared-components/ViewFragments/SearchResults/Sider/TopBarFilters/options";

const QUERY_SEARCH_URL = gql`
	query searchUrl($params: SearchParamsInput!) {
		searchUrl(params: $params) {
			url
		}
		error
	}
`;

export const useHomeFilters = () => {
	const { filters, changeFilters, filtersTags } = useFilters();
	const { country_code } = useContext(ConfigStateContext);

	useEffect(() => {
		if (country_code) {
			const currencyValue = currencyType.find(v => v.countries.includes(country_code))?.value
			let operationID = currencyValue
			let value = { text: '', value: operationID }

			if (country_code !== 'CO') {
				if (filters.operation_type_id === 1) {
					operationID = 1
					value = { text: 'U$S', value: operationID }
				}
			}

			changeFilters({ currencyID: value, m2Currency: value })
		}
	}, [filters.operation_type_id, country_code])

	const [getSearchURL, response] = useLazyQuery(QUERY_SEARCH_URL, {
		onError: errURL => console.error("ERROR", errURL),
	});

	return {
		filters,
		changeFilters,
		filtersTags,
		search: {
			send: getSearchURL,
			response: response,
		},
	};
};

