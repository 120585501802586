import { useContext, useState } from "react";
import ErrorIcon from "../CustomIcons/ErrorIcon/web";
import SuccessIcon from "../CustomIcons/SuccessIcon/web";
import WarningIcon2 from "../CustomIcons/WarningIcon2/web";
import { Modal } from "../Modal/web";
import { FormGarantiasAlquiler } from "./FormGarantíasAlquiler/web";
import './styles.less'
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { firstLetterToUppercase } from "src/utils/Functions";

export const ButtonGarantíasAlquiler = ({ isMobile }) => {
    const [show, setShow] = useState();
    const [showAlert, setShowAlert] = useState();
    const [alertData, setAlertData] = useState({
        status: "",
        message: ""
    });
    const { country_code, translations } = useContext(ConfigStateContext)

    const handleShowModal = (isVisible) => {
        setShow(isVisible)
    }

    const handleShowAlert = (isVisible) => {
        setShowAlert(isVisible)
    }

    return (
        <>
            <a className={'btn-garantia ' + (isMobile ? 'header_btn_mobile' : 'header_btn') + ` ${country_code}`} onClick={() => handleShowModal(true)} id="btn-garantia">
                {"Garantías de alquiler"}
                <span className="tag-new">¡Nuevo!</span>
            </a>

            <Modal
                show={show}
                width="800px"
                title="Garantías de Alquiler"
                onClose={() => handleShowModal(false)}
                classes="modal-garantias"
            >
                <div className="container-modal-garantias">
                    <div className="column">
                        <p className="mb-3">Ahora puedes ofrecer un servicio más integral y ganar una <strong>comisión</strong> por cada Garantía de Alquiler que derives.{" "}
                            <strong>Nosotros nos encargamos de todo.</strong>
                        </p>
                        <p>La inmobiliaria se encarga únicamente del contrato y del inventario del inmueble</p>
                        <ul className="list">
                            <li>{firstLetterToUppercase(translations.brinda)} los <strong>datos del cliente</strong></li>
                            <li>InfoCasas gestiona el análisis del crédito</li>
                            <li>Se recibe el aval de la aseguradora: Porto Seguros, MAPFRE, o Sura</li>
                            <li>Se acredita la comisión a la Inmobiliaria <br></br> (8% + IVA con Porto y Sura, 12% + IVA Mapfre, sobre la prima de la póliza) <br />
                                <span className="text-light-secondary">(se pagan a mes vencido)</span>
                            </li>
                        </ul>

                        <div className="contact">
                            <strong>¿{firstLetterToUppercase(translations.tienes)} dudas?</strong>
                            <a className="contact-btn" id="contact-consultant" href="https://api.whatsapp.com/send?phone=59897014188&text=%C2%A1Hola!%20Necesito%20informaci%C3%B3n%20sobre%20garant%C3%ADas%20de%20alquiler" target="_blank">Contacta un asesor</a>
                        </div>
                    </div>
                    <div className="column">
                        <FormGarantiasAlquiler handleShowModal={handleShowModal} handleShowAlert={handleShowAlert} setAlertData={setAlertData} />
                    </div>
                </div>
            </Modal>

            <Modal
                show={showAlert}
                width="400px"
                title=""
                onClose={() => handleShowAlert(false)}
            >
                {alertData.status === "success" ?
                    <span className="text-success"><SuccessIcon width={40} /></span> :
                    alertData.status === "warning" ?
                        <span className="text-warning"><WarningIcon2 width={40} /></span> :
                        <span className="text-error"><ErrorIcon width={40} /></span>
                }

                <h3>{alertData.message}</h3>
            </Modal>
        </>
    )
}