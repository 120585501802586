import { gql } from "@apollo/client";
import { property } from "./partials";

export const QUERY_PROPERTY_DETAILS = gql`
    query propertyDetails($id: ID!) {
        property(id:$id) {
            ${property}
        }
    }
`;
