import { filter_options } from "./partials";
import { gql } from "@apollo/client";

export const QUERY_AVAILABLE_FILTERS = gql`
	query AvailableFilters {
		availableFilters(show_in_home:true) {
			operationType {
				${filter_options}
			}
			propertyType{ ${filter_options} }
			seasons{ ${filter_options} }
			dateRange{ ${filter_options} }
			garage{ ${filter_options} }
			guests{ ${filter_options} }
			rooms {
				${filter_options}
			}
			bedrooms {
				${filter_options}
			}
			bathrooms {
				${filter_options}
			}
			floors {
				${filter_options}
      		}
			price {
				${filter_options}
			}
			commonExpenses {
				${filter_options}
			}
			surfaceRange {
				${filter_options}
			}
			propStates {
				${filter_options}
			}
			publicationDate {
				${filter_options}
			}
			seaDistance {
				${filter_options}
			}
			facilities {
				${filter_options}
			}
			disposition {
				${filter_options}
			}
			socialHousing {
				${filter_options}
			}
			privateOwner {
				${filter_options}
			}
			order {
				${filter_options}
			}
			seen {
				${filter_options}
			}
		}
	}
`;
