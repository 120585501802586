import { FragmentDefiner, useReadFragment } from "../../../GlobalHooks/useReadFragment";
import { isHighlighted, isSuperHighlighted } from "../../../Utils/Functions";

import { ConfigStateContext } from "../../../Contexts/Configurations/context";
import { useContext } from "react";

export const FRAGMENT_LISTINGTYPETAG = new FragmentDefiner(
	"Property",
	`
    project {
        id
	}
	isExternal
    highlight
    created_at
    updated_at
`
);

export function UseListingTypeTag({ id }) {
	const { country_code } = useContext(ConfigStateContext);
	const { loading, data, error } = useReadFragment(FRAGMENT_LISTINGTYPETAG, id);

	let tags = [];

	if (loading || error) return { loading, tags, error };

	if (data.project.length && country_code != "BR") {
		tags.push({ text: "Proyecto", type: "primary" });
	}

	if (data.highlight) {
		if (isSuperHighlighted(data.highlight, country_code) && country_code != "BR") {
			tags.push({ text: "Super Destacado", type: "error" });
		} else if (isHighlighted(data.highlight, country_code)) {
			tags.push({ text: "Destacado", type: "primary" });
		}
	}


	if (data.isExternal) {
		tags.push({ text: "Ficha externa", type: "primary" });
	}

	return { loading, tags, error };
}
