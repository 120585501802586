import * as React from "react"

export const HighlightBrokerIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={12}
        fill="none"
        {...props}
    >
        <g clipPath="url(#a)">
            <path
                fill={props.gold === "true" ? "#f5d508" : "#9B5BEB"}
                d="M6 0a6 6 0 1 0 0 12A6 6 0 0 0 6 0Zm3.11 5.19L7.715 6.43a.255.255 0 0 0-.065.285l.815 1.875a.245.245 0 0 1-.06.285.25.25 0 0 1-.29.035l-2-1.125a.275.275 0 0 0-.25 0l-2 1.125a.25.25 0 0 1-.29-.035.245.245 0 0 1-.06-.285l.835-1.875a.255.255 0 0 0-.065-.285L2.89 5.19a.265.265 0 0 1-.065-.28.25.25 0 0 1 .235-.16h1.655a.255.255 0 0 0 .23-.15l.825-1.92a.25.25 0 0 1 .46 0l.825 1.92a.255.255 0 0 0 .23.15H8.94a.25.25 0 0 1 .244.31.265.265 0 0 1-.074.13Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h12v12H0z" />
            </clipPath>
        </defs>
    </svg>
)