import { useContext } from "react"
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context"
import Head from "next/head"


export const ListingStructDataJson = ({listings}) => {
    const {main_domain} = useContext(ConfigStateContext)

    const jsonData = listings && listings?.length>0 && {
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": listings?.filter( i => !i?.isEspecial).map( (e,i) => {return {
            "@type": "ListItem",
            "position": i+1,
            "url": `https://${main_domain}${e?.link}`
        }})
    }
    return  <>
        <Head>
             <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify(jsonData),
                }}></script>
        </Head>
    </>
}