import "./styles.less";
import { useContext } from "react";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { genTagsText } from "shared-components/ViewFragments/SearchPage/ListingCard";
import { ProductTagIcon } from "shared-components/Components/CustomIcons/ProductTagIcon/web";
import { ListingTag } from "src/contexts/ListingTags/ListingTagsProvider";

export function ListingTypeTag({ id, data }: { id: string, data?: ListingTag }) {
	if (!data) return null;

	const { country_code } = useContext(ConfigStateContext)
	const tags = genTagsText(data, country_code)
	const ids = ["190663135"]

	return (
		<div className="tags-container">
			{tags?.map((t, i) => (
				<span className={`property-tag tag-${t.name}`} key={i}>
					{t.icon && t.icon}
					{t.text}
				</span>
			))}

			{data.tag.tag_label && data.tag.tag_label != "" &&
				<span
					color={"default"}
					key={"property_" + id + "_tag_" + data.tag.ad_id}
					className="property-tag"
				>
					<ProductTagIcon />
					{data.tag.tag_label}
				</span>
			}

			{
				ids.includes(id.toString()) &&
				<>
					<span className="lc-tags" key={8}>🌟 oportunidad</span>
					<span className="lc-tags" key={8}>🚀 tendencia</span>
				</>
			}
		</div>
	);
}
