import "./styles.less";

import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { useRouter } from "next/router";
import { ClockIcon } from "shared-components/Components/CustomIcons/ClockIcon/web";
import { ArrowIcon } from "shared-components/Components/CustomIcons/ArrowIcon/web";
import { MapMarkerIcon } from "shared-components/Components/CustomIcons/MapMarkerIcon/web";
import { CloseButton } from "shared-components/Components/CustomIcons/CloseButton/web";

dayjs.locale("es");

export const BannerFlotante = ({ data }) => {
	const router = useRouter();
	const [cssClasses, setCssClasses] = useState("");
	const { country_code } = useContext(ConfigStateContext);
	const [isClosed, setIsClosed] = useState(false);

	useEffect(() => {
		switch (router?.pathname) {
			case "/home":
				setCssClasses("home");
				break;
			case "/searchPage":
				setCssClasses("searchPage");
				break;
			case "/infocasas/eventos":
				setCssClasses("eventos");
				break;
			default:
				setCssClasses("searchPage");
				break;
		}
	}, []);

	if (!data) return null;

	return isClosed ? 
	<></>
	:
	(
		<>
			<div className={`banner-shopping-propiedades-container ${cssClasses} ${country_code}`}>
			
				<div
					className={"banner-shopping-propiedades"}					
					style={{ backgroundColor: data.border_color ?? "#fc7b27" }}
				>
					<button className="close-banner-button" onClick={()=>setIsClosed(true)}>
					<CloseButton width="10" />
					</button>

					<a href={data.link} target="_blank">
						<div className="inner" style={{ backgroundColor: data.background_color ?? "#00b3ac" }}>
							<div className="date">
								<span className="weekday">{dayjs(data.date).format("dddd")}</span>
								<span className="day">{dayjs(data.date).format("DD")}</span>
								<span className="month">{dayjs(data.date).format("MMMM")}</span>
							</div>
							<div className="content">
								<span className="title">{data.title}</span>
								<div className="down">
									{data.hours &&
										<span>
											<ClockIcon style={{ color: data.border_color ?? "#fc7b27" }} />
											{data.hours}
										</span>
									}
									{data.location &&
										<span>
											<MapMarkerIcon style={{ color: data.border_color ?? "#fc7b27" }} />
											{data.location}
										</span>
									}
								</div>
							</div>
							<div className="btn" style={{ backgroundColor: data.border_color ?? "#fc7b27" }}>
								<span>Ver más</span>
								<ArrowIcon style={{ width: '16px' }} />
							</div>
						</div>
					</a>
				</div>
			</div>
			<style>{`.banner-shopping-propiedades:hover .inner .btn { color: ${data.border_color ?? "#fc7b27"} !important;}`}</style>
		</>
	);
};

