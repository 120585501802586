import "./styles.less";

import { ConfigStateContext } from "../../../Contexts/Configurations/context";
import { ImagenOptimizada as Img } from "../../../Components/Image/_new_web";
import { useContext } from "react";

export const PicHomeFooter = () => {
	const { country_code, country_capital } = useContext(ConfigStateContext);
	const image = {
		UY: "https://cdn2.infocasas.com.uy/web/5f771b79c47c0_infocdn__montevideo.png",
		BO: "https://cdn2.infocasas.com.uy/web/60637e3b46567_infocdn__santa-cruz.png",
		PY: "https://cdn2.infocasas.com.uy/web/60637e63ee449_infocdn__asuncion.png",
		PE: "https://cdn2.infocasas.com.uy/web/60637ed9028bf_infocdn__peru.png",
		BR:
			"https://d3m49n4oy99qs7.cloudfront.net/web/th.outside1366x150.5fb2df789bb1d_infocdn__footer_image.svg",
	};

	return (
		<>
			{image[country_code] && (
				<div className={"pic-home-footer-container" + (" pic-footer-" + country_code)}>
					<div className="pic-home-footer">
						<Img
							src={image[country_code]}
							alt={country_capital}
							className={"pic-home-footer-image"}
							width={700}
							height={200}
						/>
					</div>
				</div>
			)}
		</>
	);
};
