import React, { useContext } from "react";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";

type HeaderMenuProps = {
	type: "desktop" | "mobile";
};

export const HeaderMenu = ({ type = "desktop" }: HeaderMenuProps) => {
	const { header_links, country_code, isDefault } = useContext(ConfigStateContext);
	const inmoLink = header_links ? header_links.filter((link) => link.id === "51")[0] : null;

	const navClass = type == "desktop" ? "header_desktop_menu" : "header_mobile_menu";
	const linkClass = type == "desktop" ? "header_btn" : "header_btn_mobile";

	return (
		<nav className={navClass}>
			{header_links?.map(({ link, title, id }, i) => {
				const displayNone = inmoLink ? id === inmoLink.id ? "hidden" : "" : "";

				return (
					<a className={`${linkClass} ${country_code} ${displayNone} body body-2 body-regular`} href={link} key={`headerMenu-${i}`}>
						{title}
					</a>
				)
			})}
			{country_code !== "CO" && !isDefault && (
				<a className={`${linkClass} body body-2 body-regular`} href="/eventos">
					Eventos
				</a>
			)}
		</nav>
	);
};
