import { formatMoney } from "../../../../../../Utils/Functions";
import { PaginatorInfo } from "../../SearchResultsCards.hook";


type Props = {
	map?: boolean
	paginatorInfo?: PaginatorInfo
	activeMarkers?: number
	totalMarkers?: number
	loading?: boolean
	error?: any
}

export const SearchResultDisplay = (props: Props) => {
	const activeMarkers = props.activeMarkers;
	const totalMarkers = props.totalMarkers;
	const perPage = 21

	if (props.loading || props.error || !("loading" in props) || !props.paginatorInfo || !props.paginatorInfo.total) return null;

	const { total, currentPage } = props.paginatorInfo

	if (totalMarkers == 0) {
		return <span>{"Sin resultados"}</span>;
	} else if (totalMarkers == 1) {
		return (
			<span>
				{"Mostrando"} <strong className="body body-bold">1</strong> {"resultado"}
			</span>
		);
	}

	if (activeMarkers) {
		return (
			<span>
				{"Mostrando"} <strong className="body body-bold">{activeMarkers}</strong> {"propiedades de"}{" "}
				<strong className="body body-bold">{totalMarkers > 400 ? "+400" : totalMarkers}</strong>
			</span>
		);
	}

	let text = null;

	if (total == 0) text = <>{"Sin resultados"}</>;
	else if (total == 1)
		text = (
			<>
				{"Mostrando"} <strong className="body body-bold">1</strong> {"resultado"}
			</>
		);
	else {
		const from = (currentPage - 1) * perPage + 1;
		const to = currentPage * perPage > total ? total : currentPage * perPage;
		text = (
			<>
				{"Mostrando"} <strong className="body body-bold">{formatMoney(from) + " - " + formatMoney(to)}</strong>{" "}
				de <strong className="body body-bold">{total > 400 ? "más de 400" : total}</strong> {"resultados"}
			</>
		);
	}

	return (
		<div className="search-result-display body body-2 body-regular medium">
			{text}
		</div>
	);
};
