import "./styles.less";

import React, { ReactElement } from "react";

import { FormButton } from "./FormButton/web";
import { PropComponentMode } from "../../PropertyInterfaces";
import { ModalState } from "../TextForm/type";

import dynamic from "next/dynamic"
//@ts-ignore
const WhatsappButton = dynamic(() => import("./WhatsappButton/web").then(mod => mod.WhatsappButton), { ssr: false });
const PhoneButton = dynamic(() => import("./PhoneButton/web").then(mod => mod.PhoneButton), { ssr: false });

export const LeadButtons = ({
	id,
	children = null,
	mode,
	disabled = false,
	isInListing = false,
	sizeCard,
	queryRouter,
	onlyConsult = false,
	onPhoneClick,
	onWpClick,
	forceState = ModalState.DEFAULT,
	idealFilters,
	isProject,
	owner,
	seller,
	projectId
}: {
	id: any;
	children?: ReactElement;
	mode: PropComponentMode;
	disabled?: boolean;
	isInListing?: boolean;
	sizeCard?: string;
	queryRouter?: string;
	onlyConsult?: boolean;
	onPhoneClick?: () => void;
	onWpClick?: () => void;
	forceState?: ModalState;
	idealFilters?: any,
	isProject?: boolean,
	owner?: any,
	seller?: any,
	projectId?: string,
}) => {
	const hasPhone = seller ? seller?.masked_phone : owner?.masked_phone;

	return (
		<div
			className="property-lead-button"
			style={isInListing ? { textAlign: "right" } : null}>
			<div style={{ flex: 1 }} >
				{children ?? (
					<FormButton id={id} isInListing={isInListing} sizeCard={sizeCard} idealFilters={idealFilters ?? undefined} isProject={isProject ?? false} projectId={projectId ?? ""} />
				)}
			</div>

			{hasPhone && !isInListing && !onlyConsult && (
				<div>
					<PhoneButton
						id={id}
						type={"callButton"}
						mode={mode}
						forceState={forceState}
						disabled={disabled}
						queryRouter={queryRouter}
						onPhoneClick={onPhoneClick}
						owner={owner}
						isProject={isProject}
					/>
				</div>
			)}

			{owner && owner.has_whatsapp && !isInListing && !onlyConsult && (
				<div>
					<WhatsappButton
						mode={mode}
						id={id}
						disabled={disabled}
						forceState={forceState}
						onWpClick={onWpClick}
						queryRouter={queryRouter}
						owner={owner}
						isProject={isProject}
					/>
				</div>
			)}
		</div>
	);
};



