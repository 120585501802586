import { gql } from "@apollo/client";

export const MUTATION_SEND_LEAD_REPLY = gql`
	mutation sendLeadReply($input: LeadReplyInput!) {
		sendLedReply(input: $input) {
			id
			message
			created_at
			sender
		}
	}
`;
