import { gql } from "@apollo/client";
import { property } from "./partials";

export const QUERY_REAL_ESTATE_SINGLE = gql`
	query RealEstateSingle($id: ID!) {
		realEstateSingle(id: $id) {
			id
			type
			name
			logo
			logo_v2
			profile_url
			description
			color
			font_color
			img_cover
			inmoPropsLink
			masked_phone
			has_whatsapp
			img_description
			subsidiaries {
				id
				name
				address
				email
			}
			properties(first: 6) {
				data {
					${property}
				}
			}
		}
	}
`;
